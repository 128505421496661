import { Col, Row } from 'antd'
import './job-detail.scss'
import FormProfile from '../../form/form-profile/FormProfile'
import { useEffect } from 'react';
import { dispatch, useSelector } from '../../../redux/store';
import { getDetailCareer } from '../../../redux/slices/career';
import { useParams } from 'react-router';

const JobDetail = () => {
    const { id } = useParams<{ id: string }>();
    const { careerDetail } = useSelector(state => state.career)
    const { description, content, field, name } = careerDetail;
    useEffect(() => {
        if (id) {
            dispatch(getDetailCareer(id))
        }
    }, [id])
    return (
        <section>
            <Row>
                <Col xl={14}>
                    <div className='job-detail__container'>
                        <h3 className='job-detail__title'>MÔ TẢ CÔNG VIỆC</h3>
                        <div className='job-detail__content'
                            dangerouslySetInnerHTML={{ __html: description }}
                        />
                    </div>
                    <div className='job-detail__container'>
                        <h3 className='job-detail__title'>YÊU CẦU CÔNG VIỆC</h3>
                        <div className='job-detail__content'
                            dangerouslySetInnerHTML={{ __html: content }}
                        />
                    </div>
                    <div className='job-detail__container'>
                        <h3 className='job-detail__title'>ĐỊA ĐIỂM LÀM VIỆC</h3>
                        <span className='job-detail__content'>{field}</span>
                    </div>
                </Col>
                <Col xl={10}>
                    <FormProfile id={id} name={name}/>
                </Col>
            </Row>
        </section>
    )
}

export default JobDetail