import { Checkbox, Collapse } from 'antd';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import './collapse-filter.scss';

type IProps = {
    onChange?: (checkedValues: CheckboxValueType[]) => void
    header: string;
    options: { label: string, value: string }[]
    listSelected: CheckboxValueType[]
}
const CollapseFilter = ({
    onChange,
    header,
    options,
    listSelected
}: IProps) => {
    const { Panel } = Collapse;
    return (
        <Collapse ghost className='CollapseFilter'>
            <Panel header={header} key="1" className='CollapseFilter__panel'>
                <Checkbox.Group className='Checkbox__container' options={options} defaultValue={['Pear']} onChange={onChange} value = {listSelected}  />
            </Panel>
        </Collapse>
    )
}

export default CollapseFilter