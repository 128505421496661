import { ParamsType } from "../@types/paramsType";
import { getAsync } from "./http-client";


const editWebsiteApi = {
  get: (types:string) => {
    return getAsync(`/webcontent?type=${types}`);
  },

};

export default editWebsiteApi;
