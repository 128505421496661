import { createSlice } from '@reduxjs/toolkit';

// ----------------------------------------------------------------------

const initialState = {
    isFirst: false,
};

const slice = createSlice({
    name: 'Header',
    initialState,
    reducers: {
        setIsFirst: (state, action) => {
            state.isFirst = action.payload;
        }
    },
    extraReducers: (builder) => {
        // builder.addCase(getListProduct.fulfilled, (state, action) => {

        // });
    },
});
export default slice.reducer;

export const { setIsFirst } = slice.actions;