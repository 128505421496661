import { SVGProps } from "react"

const LocationIcon = (props: SVGProps<SVGSVGElement>) => {
    return (
        <svg  {...props} width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M1 4.99072C1 2.2308 3.30633 6.10352e-05 6.09607 6.10352e-05C8.89367 6.10352e-05 11.2 2.2308 11.2 4.99072C11.2 6.38147 10.6942 7.67264 9.8617 8.76701C8.94328 9.97417 7.81129 11.0259 6.53713 11.8515C6.24551 12.0423 5.98232 12.0567 5.66227 11.8515C4.38084 11.0259 3.24885 9.97417 2.3383 8.76701C1.50519 7.67264 1 6.38147 1 4.99072ZM4.4166 5.14613C4.4166 6.0707 5.17106 6.79788 6.09613 6.79788C7.02181 6.79788 7.78353 6.0707 7.78353 5.14613C7.78353 4.22875 7.02181 3.46617 6.09613 3.46617C5.17106 3.46617 4.4166 4.22875 4.4166 5.14613Z" fill="#E2E7F1" />
        </svg>

    )
}

export default LocationIcon