import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import editWebsiteApi from "../../services/edit-website.api";
import { EditWebSiteState } from "../../@types/edit-website-type";
import { WebcontentType } from "../../constants/app-constants";

// ----------------------------------------------------------------------

export const getContentWebsite = createAsyncThunk(
  "get/content-website",
  async (types: string, { dispatch }) => {
    const { data } = await editWebsiteApi.get(types);
    return data;
  }
);
const initialState: EditWebSiteState = {
  homePage: null,
  aboutPage: null,
  constructionDesignPage: null,
  eventOrganizePage: null,
  hrmPage: null,
  serviceOtherPage: null,
};

const slice = createSlice({
  name: "edit-website",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getContentWebsite.fulfilled, (state, action) => {
      const webType = action.payload[0]?.type;
      switch (webType) {
        case WebcontentType.Home:
          state.homePage = JSON.parse(action.payload[0]?.content);
          break;
        case WebcontentType.AboutUs:
          state.aboutPage = JSON.parse(action.payload[0]?.content);
          break;
        case WebcontentType.ConstructionDesign:
          state.constructionDesignPage = JSON.parse(action.payload[0]?.content);
          break;
        case WebcontentType.EventOrganize:
          state.eventOrganizePage = JSON.parse(action.payload[0]?.content);
          break;
        case WebcontentType.HRM:
          state.hrmPage = JSON.parse(action.payload[0]?.content);
          break;
        case WebcontentType.ServiceOther:
          state.serviceOtherPage = JSON.parse(action.payload[0]?.content);
          break;
        default:
          break;
      }
    });
  },
});
export default slice.reducer;

export const {} = slice.actions;
