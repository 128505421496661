import { Carousel } from "antd";
import { useSelector } from "../../../redux/store";
import NextButton from "../../button/next-button/NextButton";
import "./section.scss";
import { useRef } from "react";

type IProps = {
  isActive: boolean;
};

const SectionOne = ({ isActive }: IProps) => {
  const { homePage } = useSelector((state) => state.editWebsite);
  const sectionOneContent = homePage?.sectionOne;
  const refCarousel: any = useRef();

  const nextBannerHandle = () => {
    refCarousel.current.next();
  }

  return (
    <Carousel
      autoplaySpeed={1500}
      dots={false}
      className={`carouselCustom `}
      autoplay
      ref={refCarousel}
    >
      {sectionOneContent &&
        sectionOneContent.map((item) => {
          return (
            <div>
              <div
                style={{
                  backgroundImage: `url(${item.banner})`,
                }}
                className="section__container"
              >
                <div className="section__wrapper">
                  <div className="section__row">
                    <div className="section__content__container">
                      <div className="section__content__divider"></div>
                      <div className="section__content__row">
                        <div className="section__content__left">
                          <span className="section__content__left__subtitle">
                            {item.subTitle}
                          </span>
                          <span className="section__content__left__title">
                            {item.title}
                          </span>
                        </div>
                        <div className="section__content__right">
                          <NextButton onClick={nextBannerHandle} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
    </Carousel>
  );
};

export default SectionOne;
