import { Col, Row } from "antd";
import firstImage from "../../../assets/images/home/section-two-first.png";
import twoImage from "../../../assets/images/home/section-two-two.png";
import NextButton from "../../button/next-button/NextButton";
import { ROUTE_PATHS } from "../../../constants/url-config";
import { useSelector } from "../../../redux/store";

const SectionTwoMobileMobile = () => {
  const { homePage } = useSelector((state) => state.editWebsite);
  const sectionTwoContent = homePage?.sectionTwo;
  return (
    <div className="sectionTwoMobile ">
      <Row className="sectionTwoMobile__list">
        <Col
          xs={24}
          sm={24}
          md={24}
          xl={8}
          className="sectionTwoMobile__item sectionTwoMobile__item--three home-section"
        >
          <Row align={"middle"}>
            <Col xs={12}>
              <div className={`sectionTwoMobile__item__title `}>
                <span>Thiết kế &nbsp; & thi công</span>
              </div>
            </Col>
            <Col xs={12}>
              <div className="sectionTwoMobile__item__nextButton">
                <NextButton isPlusIcon link={ROUTE_PATHS.Services} />
              </div>
            </Col>
          </Row>
          <div className="sectionTwoMobile__text">
            <span>{sectionTwoContent?.content}</span>
          </div>
        </Col>
        <Col xs={24} sm={24} md={24} xl={8} className="sectionTwoMobile__item">
          {/* image */}
          <div
            className="sectionTwoMobile__item__image sectionTwoMobile__item__image--first"
            style={{
              backgroundImage: `url(${sectionTwoContent?.bannerLeft})`,
            }}
          ></div>
          <div className="sectionTwoMobile__item__divider__bottom"></div>
        </Col>
        <div className="sectionTwoMobile__item__divider"></div>
        <Col
          xs={24}
          sm={24}
          md={24}
          xl={8}
          className="sectionTwoMobile__item sectionTwoMobile__item--two"
        >
          <div
            className="sectionTwoMobile__item__image sectionTwoMobile__item__image--two"
            style={{
              backgroundImage: `url(${sectionTwoContent?.bannerRight})`,
            }}
          ></div>
        </Col>
        <div className="sectionTwoMobile__item__divider"></div>
      </Row>
    </div>
  );
};

export default SectionTwoMobileMobile;
