
import { IParamsJob } from '../@types/job';
import { getAsync } from './http-client';

const api = '/career';

const careersApi = {
  getList: (params: IParamsJob) => {
    return getAsync(`${api}`, params);
  },
  getDetail: (id: string) => {
    return getAsync(`${api}/${id}`);
  }
};

export default careersApi;
