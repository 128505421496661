import { Dispatch, SetStateAction } from 'react'
import JobList from './JobList'
import './job.scss'
import { useSelector } from '../../redux/store'
import { IParamsJob } from '../../@types/job'
// type IProps = {
//   onSetDetail: Dispatch<SetStateAction<boolean>>,
// }
type IProps = {
  params: IParamsJob;
  setParams: Dispatch<SetStateAction<IParamsJob>>;
}
const Job = ({params, setParams}: IProps) => {
  const {  careerCount } = useSelector((state) => state.career);
  return (
    <section className='job'>
        <span className='job__result'>Kết quả ({careerCount})</span>
        <JobList params = {params} setParams={setParams}/>
    </section>
  )
}

export default Job