import { Col, Row } from "antd";
import inforTop from "../../../../assets/images/services/infor1.png";
import informid1 from "../../../../assets/images/services/infor2.png";
import informid2 from "../../../../assets/images/services/infor3.png";
import iconServices from "../../../../assets/icon/iconservices.svg";
import iconBottom from "../../../../assets/images/services/infor4.png";
import "./Infor.scss";
import { dispatch, useSelector } from "../../../../redux/store";
import { useEffect } from "react";
import { getServiceIntroduce } from "../../../../redux/slices/serviceEvent";
import { useLocation } from "react-router";
type Props = {
  type: string
};

const InforServices = ({ type }: Props) => {
  const { serviceIntroduce } = useSelector((state) => state.serviceEvent)
  const location = useLocation();
  const { objectName } = serviceIntroduce;
  useEffect(() => {
      dispatch(getServiceIntroduce(type))
  }, [location,type])
  return (
    <>
      {objectName ? <div dangerouslySetInnerHTML={{ __html: objectName }}></div> : <div className="containerCustom inforServices">
        <div>
          <h4 className="inforServices__title">
            Lorem ipsum dolor sit amet, cons ectetuer laoreet dolore magna aliquam
            erat
          </h4>
          <p className="subTitle inforServices__subTitle">
            Hung Long - 20/08/2023
          </p>
          <img src={inforTop} alt="" />
        </div>
        <Row gutter={[28, 20]} className="sectionContainer">
          <Col xs={24} xl={12} className="subTitle">
            Sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna
            alnpiquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud
            exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea
            commodo consequat. Lorem ipsum dolor sit amet, consectetuer adipiscing
            elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna
            aliquam erat volutpat.
          </Col>
          <Col xs={24} xl={12} className="subTitle">
            Sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna
            aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud
            exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea
            commodo consequat. Lorem ipsum dolor sit amet, consectetuer adipiscing
            elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna
            aliquam erat volutpat.
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <img src={informid1} alt="" />
          </Col>
          <Col span={12}>
            <img src={informid2} alt="" />
          </Col>
        </Row>
        <div className="inforServices__bottom sectionContainer">
          <img className="inforServices--img" src={iconServices} alt="" />
          <h5 className="inforServices__bottom--title ">
            Sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna
            aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud
            exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea
            commodo consequat. Lorem ipsum dolor sit amet, consectetuer adipiscing
            elit.
          </h5>
          <img className="sectionContainer imgCustom" src={iconBottom} alt="" />
          <p className="subTitle">Sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat.
            Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. <br />
            <br />
            Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis at vero eros et accumsan et iusto odio dignissim</p>
        </div>
      </div>
      }

    </>

  );
};

export default InforServices;
