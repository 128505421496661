import { Dropdown, Menu, Space } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { allLangs } from '../../constants/app-constants';
import { CookieConstants } from '../../constants/local';
import SVGIcons from '../icons/svgs';

const MenuLanguage = () => {
    const { t } = useTranslation();
    const langStorage = localStorage.getItem(CookieConstants.LANGUAGE) || allLangs[0];
    const currentLang = allLangs.find((_lang) => _lang.key === langStorage) || allLangs[0];
    const [defaultLang, setDefaultLang] = useState<string>(currentLang.label);


    const changeLangueHandle = (lang: string) => {
        // localStorage.setItem(CookieConstants.LANGUAGE, lang);
        // i18n.changeLanguage(lang);
        setDefaultLang(lang);
    }
    const menutop = (
        <Menu
            onClick={(e) => changeLangueHandle(e.key)}
            items={allLangs}
        />
    );
    return (
        <Dropdown overlay={menutop} className="language" >
            <Space>
                {t(`${defaultLang}`)}
                <SVGIcons.DropDownIcon />
            </Space>
        </Dropdown>
    )
}

export default MenuLanguage