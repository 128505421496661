import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import ProjectImageApi from '../../services/projectImage.api'; 
import { IProjectImage, IRenderProjectImage } from '../../@types/projectImage';


// ----------------------------------------------------------------------

export const getListProjectImage = createAsyncThunk(
  'projectImage/getListProjectImage',
  async () => {
    const { data } = await ProjectImageApi.get();
    return data;
  }
);

type IInitialState = {
  
  listHomePageImage: IRenderProjectImage[];
  listMenuImage: IProjectImage;
  listAboutUsPageImage: IProjectImage[];
  backgroundAboutUsImage: IProjectImage;
  listActivityPartnerImage: IProjectImage;
  listHomeSectionOne: IProjectImage;
  listHomeSectionTwo: IProjectImage;
  listHomeSectionThree: IProjectImage;
  listHomeSectionFour: IProjectImage;
  listHomeSectionFive: IProjectImage;
  listHomeSectionSix: IProjectImage;
  listPartner: IProjectImage;
  
};
const initialState: IInitialState = {
  listHomePageImage: [],
  backgroundAboutUsImage: {
    id: 0,
    name: '',
    description: '',
    type: 'aboutUsBackground',
    images: [],
  },
listActivityPartnerImage: {
    id: 0,
    name: '',
    description: '',
    type: 'aboutUsActivity',
    images: [],
  }
,
  listHomeSectionOne:{
    id: 0,
    name: '',
    description: '',
    type: 'homeSectionOne',
    images: [],
  },
  listHomeSectionTwo: {
    id: 0,
    name: '',
    description: '',
    type: 'homeSectionTwo',
    images: [],
  },
  listHomeSectionThree: {
    id: 0,
    name: '',
    description: '',
    type: 'homeSectionThree',
    images: [],
  },
  listHomeSectionFour: {
    id: 0,
    name: '',
    description: '',
    type: 'homeSectionFour',
    images: [],
  },
  listHomeSectionFive: {
    id: 0,
    name: '',
    description: '',
    type: 'homeSectionFive',
    images: [],
  },
  listHomeSectionSix: {
    id: 0,
    name: '',
    description: '',
    type: 'homeSectionSix',
    images: [],
  },

  listAboutUsPageImage: [],
  listMenuImage: {
    id: 0,
    name: '',
    description: '',
    type: 'menu',
    images: [],
  },
  listPartner: {
    id: 0,
    name: '',
    description: '',
    type: 'homePartner',
    images: [],
  }
};

const slice = createSlice({
  name: 'ProjectImage',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getListProjectImage.fulfilled, (state, action) => {
        // slice to 6 array
        // 1: listProjectImage
        // 2: listHomePageImage
        // 3: list

        const listPageImage: IRenderProjectImage[] = action.payload.filter(
          (item: IRenderProjectImage) => item.type
        );
    
        // -----------handle listHomePageImage--------------------
        const listHomePageImage: IRenderProjectImage[] = [];
        const listHomeSectionOne = listPageImage.find(
          (item: IRenderProjectImage) => item.type === 'homeSectionOne'
        );
        const listHomeSectionTwo = listPageImage.find(
          (item: IRenderProjectImage) => item.type === 'homeSectionTwo'
        );
        const listHomeSectionThree = listPageImage.find(
          (item: IRenderProjectImage) => item.type === 'homeSectionThree'
        );
        const listHomeSectionFour = listPageImage.find(
          (item: IRenderProjectImage) => item.type === 'homeSectionFour'
        );
        const listHomeSectionFive = listPageImage.find(
          (item: IRenderProjectImage) => item.type === 'homeSectionFive'
        );
        const listHomeSectionSix = listPageImage.find(
          (item: IRenderProjectImage) => item.type === 'homeSectionSix'
        );
        const listHomePartner = listPageImage.find(
          (item: IRenderProjectImage) => item.type === 'homePartner'
        );
        if (
          listHomeSectionOne &&
          listHomeSectionTwo &&
          listHomeSectionThree &&
          listHomeSectionFour &&
          listHomeSectionFive &&
          listHomeSectionSix &&
          listHomePartner
        ) {
          state.listHomeSectionOne = convertToProjectImageHandle(listHomeSectionOne);
          state.listHomeSectionTwo = convertToProjectImageHandle(listHomeSectionTwo);
          state.listHomeSectionThree = convertToProjectImageHandle(listHomeSectionThree);
          state.listHomeSectionFour = convertToProjectImageHandle(listHomeSectionFour);
          state.listHomeSectionFive = convertToProjectImageHandle(listHomeSectionFive);
          state.listHomeSectionSix = convertToProjectImageHandle(listHomeSectionSix);
        }
        // -----------handle listBannerImage--------------------
        // -----------handle listMenuImage--------------------

        const menuImage = listPageImage.find((item: IRenderProjectImage) => item.type === 'menu');

        if (menuImage) {
          state.listMenuImage = convertToProjectImageHandle(menuImage);
        }

        // -----------handle listAboutUsPageImage--------------------

        const backgroundAboutUsImage = listPageImage.find(
          (item: IRenderProjectImage) => item.type === 'aboutUsBackground'
        );
        const listActivityPartnerImage = listPageImage.find(
          (item: IRenderProjectImage) => item.type === 'aboutUsActivity'
        );
        if (listActivityPartnerImage && backgroundAboutUsImage) {
          state.backgroundAboutUsImage = convertToProjectImageHandle(backgroundAboutUsImage);
          state.listActivityPartnerImage = convertToProjectImageHandle(listActivityPartnerImage);
        }
        // -----------handle listAboutUsPageImage--------------------
        const listPartner = listPageImage.find(
          (item: IRenderProjectImage) => item.type === 'homePartner'
        );
        if (listPartner) {
          state.listPartner = convertToProjectImageHandle(listPartner);
        }
      });
  },
});

export default slice.reducer;

const convertToProjectImageHandle = (item: IRenderProjectImage) => {
  const images = item.images.split(',');
  return {
    ...item,
    images,
  };
}