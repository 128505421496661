
import { IParamsService } from '../@types/service';
import { getAsync } from './http-client';

const api = '/product';

const serviceApi = {
  getList: (params: IParamsService) => {
    return getAsync(`${api}`, params);
  },
  getDetail: (id: string) => {
    return getAsync(`${api}/${id}`);
  }
};

export default serviceApi;
