import { Col, Row } from 'antd'
import firstImage from '../../../assets/images/home/section_four_second.png'
import twoImage from '../../../assets/images/home/section_four_first.png'
import NextButton from '../../button/next-button/NextButton'
import './section.scss'
import { ROUTE_PATHS } from '../../../constants/url-config'
import { useSelector } from '../../../redux/store'

type IProps = {
  isActive: boolean
}

const SectionFourMobile = () => {

  const { homePage } = useSelector((state) => state.editWebsite);
  const sectionFourContent = homePage?.sectionFour;
  return (
    <div className="sectionFourMobile ">
      <Row className="sectionFourMobile__container">
         <Col xs={24} sm={24} md={24} lg={6} xl={8} className='sectionFourMobile__right home-section'>
        <Row align={'middle'}>
          <Col xs={12}>

          <span className={`sectionFourMobile__right__title }`}>quản lý 
            <br />nhân sự</span>
          </Col>
           <Col xs={12}>
          <div className='sectionTwoMobile__item__nextButton'>
            <NextButton isPlusIcon link={ROUTE_PATHS.HRM} />
          </div>
            
          </Col>

        </Row>
          <span className='sectionFourMobile__right__content'>
            {sectionFourContent?.content}
          </span>

        </Col>
        <Col xs={24} sm={24} md={24} lg={18} xl={16} className='sectionFourMobile__left'>
          <div className="sectionFourMobile__left__image"
            style={{
              backgroundImage: `url(${sectionFourContent?.bannerLeft})`,
            }}
          >
          </div>
        </Col>
        <Col style={{marginTop: "2rem"}} xs={24} sm={24} md={24} lg={18} xl={16} className='sectionFourMobile__left'>
          <div className="sectionFourMobile__left__image"
            style={{
              backgroundImage: `url(${sectionFourContent?.bannerRight})`,
            }}
          >
          </div>
        </Col>

       
      </Row>
    </div>
  )
}

export default SectionFourMobile