import { Tabs } from 'antd';
import TitleComponent from '../../components/views/title-component/TitleComponent';
import '../hrm/hrm.scss';
import Recruitment from '../../components/views/hrm/recruitment/Recruitment';
import TabsComponent from '../../components/tabs/Tabs';
import { useParams } from 'react-router';
import { useState } from 'react';
import { OBJECT_TYPE } from '../../constants/app-constants';


const RecruitmentDetail = () => {
    const {id} = useParams(); 
    const [type, setType] = useState(OBJECT_TYPE.recruitment.job);
    console.log("🚀 ~ file: RecruitmentDetail.jsx:14 ~ RecruitmentDetail ~ type:", type)
    const tabItems = [
    {
        label: "Tuyển dụng",
         key: OBJECT_TYPE.recruitment.job,
        children: <Recruitment id={id} type={type}/>,
    },
    {
        label: "Quản lý nhân sự",
        key: OBJECT_TYPE.recruitment.hrm,
        children: <Recruitment type={type}/>,

    },
    {
        label: "Hệ thống quản lý",
         key: OBJECT_TYPE.recruitment.managementSystem,
        children: <Recruitment type={type}/>,

    },
    ];

    const onChangeHandle = (e: number) => {
        setType(e);
    }

    return (
        <section className="HRMPage">
            <TitleComponent
                title="QUẢN LÝ NHÂN SỰ"
                desc="Lorem ipsum dolor sit amet, cons ectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat."
                isPrimary={false}
            />
            <TabsComponent type={type} isPrimary={false} items={tabItems} onChange={onChangeHandle} />
        </section>
    )
}

export default RecruitmentDetail;