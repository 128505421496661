import { RouteChildrenProps } from "react-router";
import { LayoutProps } from "../../components/views/layout/route-layout";
import "./home.scss";
// Photos from https://citizenofnowhe.re/lines-of-the-city
import "aos/dist/aos.css";
import { useEffect, useRef, useState } from "react";
import ReactPageScroller from "react-page-scroller";
import Footer from "../../components/views/layout/footer/Footer";
import SectionEightMobile from "../../components/views/section/SectionEightMobile";
import SectionFive from "../../components/views/section/SectionFive";
import SectionFiveMobile from "../../components/views/section/SectionFiveMobile";
import SectionFour from "../../components/views/section/SectionFour";
import SectionFourMobile from "../../components/views/section/SectionFourMobile";
import SectionOne from "../../components/views/section/SectionOne";
import SectionSeven from "../../components/views/section/SectionSeven";
import SectionSevenMobile from "../../components/views/section/SectionSevenMobile";
import SectionSix from "../../components/views/section/SectionSix";
import SectionThree from "../../components/views/section/SectionThree";
import SectionThreeMobile from "../../components/views/section/SectionThreeMobile";
import SectionTwo from "../../components/views/section/SectionTwo";
import SectionTwoMobile from "../../components/views/section/SectionTwoMobile";
import { WebcontentType } from "../../constants/app-constants";
import useResponsive from "../../hooks/useResponsive";
import { getContentWebsite } from "../../redux/slices/edit-website";
import { setIsFirst } from "../../redux/slices/header";
import { dispatch, useSelector } from "../../redux/store";
import NextButton from "../../components/button/next-button/NextButton";



interface Props extends RouteChildrenProps, LayoutProps {}

export default function HomePage(props: Props) {
  const [ref, setRef] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const { isMobile, isTablet } = useResponsive();
  const { homePage } = useSelector((state) => state.editWebsite);

  const changePageHandle = (number: number) => {
    setCurrentPage(number);
  };

  useEffect(() => {
    if (currentPage === 0) {
      dispatch(setIsFirst(true));
    } else {
      dispatch(setIsFirst(false));
    }
  }, []);

  const updateHeaderHandle = (page: number) => {
    if (page === 0) {
      dispatch(setIsFirst(true));
    } else {
      dispatch(setIsFirst(false));
    }
  };

  useEffect(() => {
    if (!homePage) {
      dispatch(getContentWebsite(WebcontentType.Home));
    } 
  }, [homePage]);

  return (
    <div className="app-bg">
      {isMobile ? (
        <>
          <SectionOne key={1} isActive={currentPage === 0} />
          <SectionTwoMobile key={2} />
          <SectionThreeMobile key={3} />
          {isMobile || isTablet ? (
            <SectionFourMobile key={4} />
          ) : (
            <SectionFour key={4} isActive={currentPage === 3} />
          )}
          {/* <SectionFour isActive = {currentPage === 3}/> */}
          {/* <SectionFourMobile /> */}
          <SectionFiveMobile key={5} />
          <SectionSix key={6} />
          {/* {(isMobile || isTablet) &&
            <SectionSevenMobile key={7}/>
        }
        {(isMobile || isTablet) &&
            <SectionEightMobile key={8}/>
        } */}
          <SectionSeven key={7} />
          {/* {(isMobile || isTablet) &&
              <Footer key={9}/>
        } */}
          {!isMobile && !isTablet && <SectionSeven key={7} />}
        </>
      ) : (
       <>
       <div className="homepage__up">
        {currentPage !== 0 && <NextButton isUp onClick={()=>setCurrentPage(0)}/>}
        </div>
         <ReactPageScroller
           transitionTimingFunction="cubic-bezier(0.4, 0.0, 0.2, 1)"
           pageOnChange={(page) => changePageHandle(page)}
           onBeforePageScroll={(page) => updateHeaderHandle(page)}
        customPageNumber = {currentPage}
          
         >
           <SectionOne key={1} isActive={currentPage === 0} />
           <SectionTwo key={2} isActive={currentPage === 1} />
           <SectionThree key={3} isActive={currentPage === 2} />
           {isMobile || isTablet ? (
             <SectionFourMobile key={4} />
           ) : (
             <SectionFour key={4} isActive={currentPage === 3} />
           )}
           {/* <SectionFour isActive = {currentPage === 3}/> */}
           {/* <SectionFourMobile /> */}
           <SectionFive key={5} />
           <SectionSix key={6} />
           {(isMobile || isTablet) && <SectionSevenMobile key={7} />}
           {(isMobile || isTablet) && <SectionEightMobile key={8} />}
           {(isMobile || isTablet) && <Footer key={9} />}
           {!isMobile && !isTablet && <SectionSeven key={7} />}
         </ReactPageScroller>
       </>
      )}
    </div>
  );
}
