import { Tabs } from 'antd';
import TitleComponent from '../../components/views/title-component/TitleComponent';
import './hrm.scss';
import Recruitment from '../../components/views/hrm/recruitment/Recruitment';
import TabsComponent from '../../components/tabs/Tabs';
import { DEFAULT_PAGINATION, OBJECT_TYPE, WebcontentType } from '../../constants/app-constants';
import { useEffect, useState } from 'react';
import { dispatch, useSelector } from '../../redux/store';
import { getListCareer } from '../../redux/slices/career';
import { IParamsJob } from '../../@types/job';
import { getContentWebsite } from '../../redux/slices/edit-website';


const HRMPage = () => {

    const { hrmPage } = useSelector((state) => state.editWebsite);
    const [type, setType] = useState(OBJECT_TYPE.recruitment.job);

    const tabItems = [
        {
            label: "Tuyển dụng",
            key: OBJECT_TYPE.recruitment.job,
            children: <Recruitment type={type} />,
        },
        {
            label: "Quản lý\nnhân sự",
            key: OBJECT_TYPE.recruitment.hrm,
            children: <Recruitment type={type} />,

        },
        {
            label: "Hệ thống\nquản lý",
            key: OBJECT_TYPE.recruitment.managementSystem,
            children: <Recruitment type={type} />,

        },
    ];

    const getCareerList = async (options: IParamsJob) => {
        await dispatch(getListCareer(options));
    };

    const onChangeHandle = (e: number) => {
        getCareerList({
            pageIndex: DEFAULT_PAGINATION.PAGE_INDEX,
            pageSize: DEFAULT_PAGINATION.PAGE_SIZE,
            keyword: '',
            // brand_id: e
        });
        // setType(e);
    }

    useEffect(() => {
      if (!hrmPage) {
        dispatch(getContentWebsite(WebcontentType.HRM));
      } 
    }, [hrmPage]);
    return (
        <section className="HRMPage">
            <TitleComponent
                title="QUẢN LÝ NHÂN SỰ"
                desc={hrmPage?.content}
                isPrimary={false}
            />
            <TabsComponent isPrimary={false} items={tabItems} onChange={onChangeHandle} />
        </section>
    )
}

export default HRMPage