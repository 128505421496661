import { SVGProps } from "react"

const DownArrowIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
   <svg {...props} width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M3.9375 0.937365L3.9375 2.10924C3.9375 2.18893 3.96741 2.26393 4.01645 2.3108L8.70112 6.74986L4.01646 11.1889C3.96741 11.2358 3.9375 11.3108 3.9375 11.3905L3.9375 12.5624C3.9375 12.6639 4.02603 12.7233 4.08943 12.6639L9.90459 7.15455C10.1151 6.95455 10.1151 6.54518 9.90459 6.34674L4.08943 0.837364C4.02603 0.776426 3.9375 0.835802 3.9375 0.937365Z" fill="black"/>
</svg>

  )
}

export default DownArrowIcon