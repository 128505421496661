import { Col, Row } from 'antd'
import sectionImageBanner from '../../../assets/images/home/section_four_first.png'
import sectionRightImageBanner from '../../../assets/images/home/section_four_second.png'
import NextButton from '../../button/next-button/NextButton'
import { ROUTE_PATHS } from '../../../constants/url-config'
import { useSelector } from '../../../redux/store'
import useResponsive from '../../../hooks/useResponsive'
type IProps = {
  isActive: boolean
}
const SectionFour =  ({isActive}:IProps) => {

  const { homePage } = useSelector((state) => state.editWebsite);
  const sectionFourContent = homePage?.sectionFour;
  const { isLargeDesktop } = useResponsive();
  return (
    <div className="sectionFour home-section">
      <Row className="sectionFour__container">
        <Col xs={0} lg={15} xl={16} style={{ paddingRight: "2.4rem", overflow: "hidden" }}>
          <span className={`sectionFour__left__title ${isActive ? 'animate' : ''}`}>
            {isLargeDesktop ?

              <span className={`sectionFour__left__title ${isActive ? 'animate' : ''}`}>
                quản lý <br />nhân sự
              </span>
              : (
                <span className={`sectionFour__left__title ${isActive ? 'animate' : ''}`}>
                  quản lý nhân sự
                </span>


              )}

          </span>
          <Row style={{ marginTop: '4rem', height: "100%" }}>
            <Col xs={{ offset: 0 }} lg={{ offset: 4 }} xl={{ offset: 6 }}  ></Col>
            <Col xs={18} lg={20} xl={18} >
              <div style={{
                backgroundImage: `url(${sectionFourContent?.bannerLeft})`,
              }} className='sectionFour__left__image'>

              </div>
            </Col>
          </Row>

        </Col>
        <Col xs={24} lg={9} xl={8} style={{ height: "100%" }}>
          <Row style={{ height: "100%" }}>
            <Col xl={1} lg={1} xs={0}>
              <div className="sectionTwo__item__divider"></div>
            </Col>
            <Col xs={24} lg={23} xl={23}>
              <div className="sectionFour__right__container">
                <div className="sectionFour__right__image"
                  style={{
                    backgroundImage: `url(${sectionFourContent?.bannerRight})`,
                  }}
                ></div>
                <div className='sectionTwo__item__nextButton'>
                  <NextButton isPlusIcon link={ROUTE_PATHS.HRM} />
                </div>
                <span className='sectionThree__right__content'>
                  {sectionFourContent?.content}
                </span>
              </div>
            </Col>
          </Row>

        </Col>
      </Row>
    </div>
  )
}

export default SectionFour