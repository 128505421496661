import { Col, Row } from "antd";
import sectionFive from "../../../assets/images/home/section_five.png";
import NextButton from "../../button/next-button/NextButton";
import { useSelector } from "../../../redux/store";

const SectionFive = () => {
  const { homePage } = useSelector((state) => state.editWebsite);
  const sectionFiveContent = homePage?.sectionFive;
  return (
    <div className="sectionFive">
      <Row
        style={{ flexDirection: "row-reverse" }}
        className="sectionFive__container"
      >
        <Col
          xs={24}
          md={24}
          sm={24}
          lg={14}
          xl={16}
          className="sectionFive__right__wrapper"
        >
          <div className="sectionFive__right__container">
            <div
              className="sectionFive__right__image"
              style={{
                backgroundImage: `url(${sectionFiveContent?.banner})`,
              }}
            ></div>
          </div>
        </Col>
        <Col
          xs={24}
          md={24}
          sm={24}
          lg={10}
          xl={8}
          className="sectionFive__left__wrapper"
        >
          <Row style={{ height: "100%" }}>
            <Col
              xs={18}
              md={18}
              sm={18}
              lg={23}
              xl={22}
              style={{ height: "100%" }}
            >
              <div className="sectionFive__left__container">
                <div className="sectionFive__left__title">
                  <span>{sectionFiveContent?.title}</span>
                </div>
                <div className="sectionFive__left__subtitle">
                  <span>{sectionFiveContent?.subtitle}</span>
                </div>
                <div className="sectionTwo__item__nextButton">
                  <NextButton isPlusIcon />
                </div>
              </div>
            </Col>
            <Col xs={6} md={6} sm={6} lg={0} xl={0}>
              <div className="sectionTwo__item__nextButton">
                <NextButton isPlusIcon />
              </div>
            </Col>
            <Col
              xs={0}
              md={0}
              sm={0}
              lg={1}
              xl={2}
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <div className="sectionTwo__item__divider"></div>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default SectionFive;
