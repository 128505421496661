import { Col, Row } from 'antd'
import './footer.scss'
import NextButton from '../../../button/next-button/NextButton'
type IProps = {
  isHome?: boolean
}
const Footer = ({isHome}: IProps) => {
  return (
    <>
      <section className={`footer ${isHome && 'footer--home'}`}>
          <ul className='footer__policy'>
              <li className='footer__policy__content'>Chính sách bán hàng</li>
              <li className='footer__policy__content'>Chính sách bảo hành</li>
              <li className='footer__policy__content'>Chính sách đổi trả</li>
              <li className='footer__policy__content'>Giao nhận và lắp đặt</li>
              <li className='footer__policy__content'>Chính sách bảo vệ thông tin người dùng</li>
          </ul>
          <div className='footer__license__container'>
              <span className='footer__license'>© 2023 Hung Long. Đã đăng ký bản quyền.</span>
          </div>
      </section>
      <Row className='footer__license--mobile'>
        <Col xs={12} md={0} lg={0} >
              <div className='footer__license__container--mobile'>
              <p className='footer__license'>© 2023 Hung Long.</p>
              <p className='footer__license footer__license--bottom'>Đã đăng ký bản quyền.</p>
          </div>
        </Col>
        <Col xs={12} md={0} lg={0} className='footer__button--mobile'>
          <NextButton isUp link='#'/>
        </Col>
      </Row>
    </>
  )
}

export default Footer