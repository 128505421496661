import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { OBJECT_TYPE } from "../../constants/app-constants";
import { IParamsService, ServiceState } from "../../@types/service";

import serviceApi from "../../services/service.api";

export const getServiceEvent = createAsyncThunk(
  'product/getList',
  async (params: IParamsService, { dispatch }) => {
    const { data } = await serviceApi.getList({ ...params, type: OBJECT_TYPE.service.event});
    return data;
  }
);

export const getServiceDesign = createAsyncThunk(
  'product/getListDesign',
  async (params: IParamsService, { dispatch }) => {
    const { data } = await serviceApi.getList({ ...params, type: OBJECT_TYPE.service.constructionDesign});
    return data;
  }
);
export const getServiceDetail = createAsyncThunk(
  'product/getDetail',
  async (id: string, { dispatch }) => {
    const { data } = await serviceApi.getDetail(id);
    return data;
  }
);

const initialState: ServiceState = {
  serviceList: [],
  serviceCount: 0,
  serviceDetail: {
    id: 0,
    images:'',
    name: '',
    typeName:'',
    description: '',
    thumbnail: '',
    type:0,
  },

};

const slice = createSlice({
  name: 'service',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getServiceEvent.fulfilled, (state, action) => {
      state.serviceList = action.payload.items;
      state.serviceCount = action.payload.totalRow;
    })
    builder.addCase(getServiceDesign.fulfilled, (state, action) => {
      state.serviceList = action.payload.items;
      state.serviceCount = action.payload.totalRow;
    })
  },
});
export default slice.reducer;
