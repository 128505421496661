import { LayoutProps } from "antd";
import { RouteChildrenProps } from "react-router";
import "./ServicesOther.scss";
import Furniture from "../../components/views/services/other/furniture/Furniture";
import TitleComponent from "../../components/views/title-component/TitleComponent";
import TabsComponent from "../../components/tabs/Tabs";
import { dispatch, useSelector } from "../../redux/store";
import { useEffect } from "react";
import { getContentWebsite } from "../../redux/slices/edit-website";
import { WebcontentType } from "../../constants/app-constants";

interface Props extends RouteChildrenProps, LayoutProps {}
const tabItems = [
    {
      label: "Quà tặng",
      key: "1",
      children: <Furniture isGift/>,
    },
    {
      label: "Sản phẩm nội thất",
      key: "2",
       children: <Furniture isGift = {false}/>,
    },
    // {
    //   label: "Sản phẩm nội thất",
    //   key: "32",
    //    children: <Furniture isGift = {false}/>,
    // },
  ];
export default function ServicesOtherPage(props: Props) {
  const { serviceOtherPage } = useSelector((state) => state.editWebsite);

  useEffect(() => {
    if (!serviceOtherPage) {
      dispatch(getContentWebsite(WebcontentType.ServiceOther));
    } 
  }, [serviceOtherPage]);
  return (
    <div className="servicesOther">
      <TitleComponent
        title="DỊCH VỤ KHÁC"
        desc={serviceOtherPage?.content}
      />
      <TabsComponent items={tabItems}/>
    </div>
  );
}
