import { Col, Row } from 'antd'

import { FacebookIcon, InstagramIcon, ZaloIcon } from "../../../components/icons";
import recognize from '../../../assets/images/home/recognize.png';
import '../../../pages/contact/contact.scss';
import './section.scss'
import FormContact from '../../form/form-contact/FormContact';
import Footer from '../layout/footer/Footer';
const SectionEightMobile = () => {
  return (
    <div className="contact__right__container">
      <FormContact/>
      {/* <Footer isHome/> */}
    </div>
  )
}

export default SectionEightMobile