
const UploadIcon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="UploadOutlined">
                <path id="Vector" d="M9.00028 6.79555H10.9797V15.8572C10.9797 15.975 11.0762 16.0714 11.194 16.0714H12.8012C12.919 16.0714 13.0155 15.975 13.0155 15.8572V6.79555H15.0003C15.1797 6.79555 15.2788 6.5893 15.169 6.45001L12.169 2.6518C12.149 2.62619 12.1234 2.60547 12.0941 2.59123C12.0649 2.57698 12.0328 2.56958 12.0003 2.56958C11.9678 2.56958 11.9357 2.57698 11.9064 2.59123C11.8772 2.60547 11.8516 2.62619 11.8315 2.6518L8.83153 6.44733C8.72171 6.5893 8.82081 6.79555 9.00028 6.79555ZM21.8039 15.0536H20.1967C20.0788 15.0536 19.9824 15.15 19.9824 15.2679V19.3929H4.01814V15.2679C4.01814 15.15 3.92171 15.0536 3.80385 15.0536H2.19671C2.07885 15.0536 1.98242 15.15 1.98242 15.2679V20.5714C1.98242 21.0455 2.36546 21.4286 2.83956 21.4286H21.161C21.6351 21.4286 22.0181 21.0455 22.0181 20.5714V15.2679C22.0181 15.15 21.9217 15.0536 21.8039 15.0536Z" fill="black" />
            </g>
        </svg>

    )
}

export default UploadIcon