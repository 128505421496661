import { getAsync } from './http-client';

const url = 'projectimages';
const ProjectImageApi = {
  get: (params?: { type: string }) => {
    return getAsync(`/${url}`, params);
  },
  getBanners: (params: any) => {
    return getAsync(`/${url}`, params);
  },
  
};

export default ProjectImageApi;
