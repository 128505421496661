import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import careersApi from '../../services/careers.api';
import i18next from 'i18next';
import { CareerState, ICareerCreated, IParamsJob } from '../../@types/job';
import { OBJECT_TYPE } from '../../constants/app-constants'; 

export const getListCareer = createAsyncThunk(
  'career/getList',
  async (params: IParamsJob, { dispatch }) => {
    const { data } = await careersApi.getList({ ...params});
    return data;
  }
);

export const getMoreListCareer = createAsyncThunk(
  'career/getMoreList',
  async (params: IParamsJob, { dispatch }) => {
    const { data } = await careersApi.getList({ ...params });
    return data;
  }
);


export const getDetailCareer = createAsyncThunk(
  'career/getDetail',
  async (id: string, { dispatch }) => {
    const { data } = await careersApi.getDetail(id);
    return data;
  }
);

const initialState: CareerState = {
  listCareer: [],
  careerCount: 0,
  careerDetail: {
    id: 0,
    province_id: 0,
    name: '',
    jobType: '',
    content: '',
    description: '',
    level: '',
    provinceName: '',
    thumbnail: '',
    workingType: '',
    field: '',
  },
  objectTypeCarrer: 0,
};

const slice = createSlice({
  name: 'career',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getListCareer.fulfilled, (state, action) => {
        state.listCareer = action.payload.items;
        state.careerCount = action.payload.totalRow;
      })
      .addCase(getMoreListCareer.fulfilled, (state, action) => {
        // if (state.listCareer.length > 0) {
          state.listCareer = [...state.listCareer, ...action.payload.items];
        //   return;
        // }
        // state.listCareer = action.payload.items;
        state.careerCount = action.payload.totalRow;
      })
      .addCase(getDetailCareer.fulfilled, (state, action) => {
        state.careerDetail = action.payload;
      })
    
  },
});
export default slice.reducer;
