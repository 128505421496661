import { SVGProps } from "react"

const ModalCloseIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
   <svg {...props} width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<g id="Menu">
<g id="Group 50">
<g id="Group">
<path id="Vector" d="M2.6665 2.66672L29.3331 29.3334" stroke="white" stroke-width="0.821485" stroke-linecap="round" stroke-linejoin="round"/>
<path id="Vector_2" d="M29.3335 2.66667L2.66685 29.3333" stroke="white" stroke-width="0.821485" stroke-linecap="round" stroke-linejoin="round"/>
</g>
</g>
</g>
</svg>


  )
}

export default ModalCloseIcon