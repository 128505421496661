import "./TitleComponent.scss";

type Props = {
    title:string;
    desc?:string;
    isPrimary?:boolean;
};

const TitleComponent = ({title,desc,isPrimary = true}: Props) => {
  return (
   <div className={`titleComponent containerCustom ${!isPrimary ? 'titleComponent__primary':''}`}>
        <h2 className="titleComponent__title title">{title}</h2>
        <p className="titleComponent__desc subTitle">{desc}</p>
   </div>
  );
};

export default TitleComponent;
