import { Tabs } from "antd";
import "./Tabs.scss";
import { OBJECT_TYPE } from "../../constants/app-constants";

type Props = {
  items: any;
  isPrimary?: boolean;
  defaultActiveKey?: string;
  onChange?: (e: any) => void;
};

const TabsComponent = ({ items, isPrimary = true, defaultActiveKey = "1", onChange }: Props) => {

  return (
    <Tabs
      className={`tabService ${!isPrimary ? 'tabService__primary' : ''} `}
      defaultActiveKey={defaultActiveKey}
      items={items}
      centered
      onChange={onChange}
    />
  );
};

export default TabsComponent;
