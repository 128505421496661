import { Col, Row } from 'antd'
import './job.scss'
import { CalenderIcon, DownArrowIcon, LocationIcon } from '../icons'
import StarIcon from '../icons/StarIcon'
import { motion } from "framer-motion";
import { ICareer } from '../../@types/job';
import { useHistory } from 'react-router';
import { ROUTE_PATHS } from '../../constants/url-config';
import useResponsive from '../../hooks/useResponsive';
type IProps = {
    job: ICareer
}
const JobItem = ({ job }: IProps) => {
    const history = useHistory();
    const {isMobile} = useResponsive();
    const { id, name, thumbnail, level, field, workingType, provinceName, jobType } = job;
    const nameRender =  isMobile? name.slice(0, 20) + "..." : name;
    const levelRender = isMobile? level.slice(0, 15) + "..." : level;
    const goToDetailHandle = () => {
        const href = ROUTE_PATHS.RecruitmentDetail.replace(
            ":id",
            id.toString()
        );
        history.push(href);
    }
    return (
        <section className='job__item'>
            <Row  align={{md: 'middle', xs: 'top'}} style={{ height: "100%", width: "100%" }} onClick={() => goToDetailHandle()}>
                <Col xs={6} lg={4} xl={3} style={{ height: '100%' }}>
                    <div className='job__item__logo'>
                        <img src={thumbnail} alt='thumbnail-job' />
                    </div>
                </Col>
                <Col xs={18} lg={19} xl={20}>
                    <div className='job__item__container'>
                        <span className='job__item__title'>{nameRender}</span>
                        <div className='job__item__description'>
                            <div className='job__item__description__container'>
                                <StarIcon />
                                <span>{levelRender}</span>
                            </div>
                            <div className='job__item__description__container job__item__description__container--divider'>
                                <LocationIcon />
                                <span>{provinceName}</span>
                            </div>
                            <div className='job__item__description__container'>
                                <CalenderIcon />
                                <span>{jobType}</span>
                            </div>
                        </div>
                    </div>
                </Col>
                <Col xs={0} lg={1} xl={1} style={{ textAlign: "right" }}>
                    <div className='job__item__action'>
                        <DownArrowIcon />
                    </div>
                </Col>
            </Row>
        </section>
     
    )
}

export default JobItem