import i18next from "i18next";

export const PAGE_INDEX = 1;

export const OBJECT_TYPE = {
  recruitment: {
    job: 32,
    hrm: 33,
    managementSystem: 34,
  },
  otherService: {
    gift: 99,
    furnitureProduct: 98,
  },
  constructionDesign: {
    introduce: "constructionDesignIntroduce",
    procedure: "constructionDesignProcedure",
  },
  events: {
    introduce: "eventsIntroduce",
    procedure: "eventsProcedure",
  },
  service: {
    event: 97,
    constructionDesign: 96,
  },
};

export const DEFAULT_PAGINATION = {
  PAGE_INDEX: 1,
  PAGE_SIZE: 10,
  PAGE_ONE: 1,
  GET_ALL: 10000,
};

export const dirNameS3 = {
  recruitment: "recruitment",
};

export const S3_PROJECT = "hunglong";

export const allLangs = [
  {
    key: `VN`,
    label: i18next.t("VN"),
  },
  {
    key: `EN`,
    label: i18next.t("EN"),
  },
];


export enum WebcontentType {
    Home = 'home',
    AboutUs = 'aboutUs',
    ConstructionDesign = 'constructionDesign',
    EventOrganize = 'eventOrganize',
    HRM = 'hrm',
    ServiceOther = 'serviceOther',
}