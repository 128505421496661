import { Button, Col, DatePicker, Form, Input, Row, Select, Upload, notification } from 'antd';
import './form-profile.scss'
import { useState } from 'react';
import { UploadIcon } from '../../icons';
import { RcFile } from 'antd/lib/upload';
import Utils from '../../../utils/utils';
import { dirNameS3 } from '../../../constants/app-constants';
import { dispatch } from '../../../redux/store';
import { postApplyCareer } from '../../../redux/slices/apply-careers';
type IProps = {
    id: string;
    name: string;
}
const FormProfile = ({ id, name }: IProps) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [arrFile, setArrFile] = useState<any[]>([]);

    const checkFileSize = (file: RcFile) => {
        const maxSize = 5 * 1024 * 1024; // 5 MB (you can modify this size as needed)
        if (file.size > maxSize) {
            setArrFile([]);
            return false; // Prevent uploading
        }
        return true; // Proceed with uploading
    };



    const onFinish = async (data: any) => {
        setLoading(true);

        try {
            let url = ''
            if (arrFile.length) {
             const res = await Utils.uploadFile(
                arrFile[0].originFileObj,
                dirNameS3.recruitment,
                `Đơn ứng tuyển - ${data.fullName}`
            );
            res && (url = res);
            }
            const submitData = {
                ...data,
                career_id: id,
                url,
                name: name,
            };
            setArrFile([]);
            await dispatch(postApplyCareer(submitData));
            form.resetFields();
            Utils.openNotification('success', 'Nộp đơn thành công');
        }
        catch (error) {
            setLoading(false);
            Utils.openNotification('error', 'Nộp đơn thất bại');
        }
        finally {
            setLoading(false);
            //   handleClose();
        }
        
    };
    // Custom validation rule for email
    const validateEmail = (_: any, value: string) => {
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        if (value && !emailRegex.test(value)) {
            return Promise.reject('Please enter a valid email!');
        }
        return Promise.resolve();
    };

    const validatePhoneNumber = (_: any, value: string) => {
        const phoneRegex = /(84|0[2|3|5|7|8|9])+([0-9]{8,9})\b/;
        if (value && !phoneRegex.test(value)) {
            return Promise.reject('Please enter a valid Vietnamese phone number');
        }
        return Promise.resolve();
    };

    const checkIsLinkHandle = (value: string) => {
        const regex = /(https?:\/\/[^\s]+)/g;
        if (value && !regex.test(value)) {
            return false;
        }
        return true;
    }

    

    return (
        <section className="form-profile">
            <div className='form-profile__container'>
                <Form form={form} name="formJob" onFinish={onFinish}>
                    <Row className="form-profile__wrapper">
                        <Col xs={24} className="form-profile__title">
                            NỘP HỒ SƠ CỦA BẠN
                        </Col>
                        <Col xs={24} className="form-profile__input__wrapper">
                            <div className="form-profile__label">
                                Họ và Tên
                                <span className='required'>*</span>
                            </div>
                            <Form.Item
                                name="fullName"
                                rules={[
                                    { required: true, message: "Vui lòng nhập họ và tên" },
                                ]}
                            >
                                <Input
                                    className="form-profile__input"
                                    placeholder="Họ và tên"
                                />
                            </Form.Item>
                        </Col>

                        <Col xs={24} className="form-profile__input__wrapper">
                            <div className="form-profile__label">
                                Email
                                <span className='required'>*</span>
                            </div>
                            <Form.Item
                                name="email"
                                rules={[
                                    { required: true, message: "Vui lòng nhập email" },
                                    { validator: validateEmail, message: 'Email không hợp lệ' },
                                ]}
                            >
                                <Input
                                    className="form-profile__input"
                                    placeholder="hunglong@gmail.com"
                                />
                            </Form.Item>
                        </Col>

                        <Col xs={24} className="form-profile__input__wrapper">
                            <div className="form-profile__label">
                                Số điện thoại
                                <span className='required'>*</span>
                            </div>
                            <Form.Item
                                name="phoneNumber"
                                rules={[
                                    { required: true, message: "Vui lòng nhập số điện thoại" },
                                    { min: 10, message: 'Số điện thoại bao gồm 10-11 số' },
                                    { max: 11, message: 'Số điện thoại bao gồm 10-11 số' },
                                    { validator: validatePhoneNumber, message: 'Số điện thoại không hợp lệ' },

                                ]}
                            >
                                <Input
                                    className="form-profile__input"
                                    placeholder="Số điện thoại"
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} className="form-profile__input__wrapper">
                            <div className="form-profile__label">
                                Liên kết Sơ yếu lý lịch/CV Link
                            </div>
                            <Form.Item
                                name="cvUrl"
                                rules={[
                                    {
                                        validator: (_, value) => {
                                            if (!value) 
                                                return Promise.resolve();

                                            const isLink = checkIsLinkHandle(value)
                                            if (isLink) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(
                                                new Error("Đường link không hợp lệ, vui lòng nhập lại đường")
                                            );
                                        },
                                    }
                                ]}
                            >
                                <Input
                                    className="form-profile__input"
                                    placeholder="Link "
                                />
                            </Form.Item>
                        </Col>

                        <Col xs={24} className="form-profile__input__wrapper">
                            <div className="form-profile__label">
                                Hoặc tải lên tập tin của bạn
                            </div>
                            <Form.Item name="url"
                                rules={[
                                    {
                                        validator: (_, value) => {
                                            if (!value) 
                                                return Promise.resolve();

                                            const isFileSize = checkFileSize(value.file)
                                            if (isFileSize) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(
                                                new Error("Chúng tôi chấp nhận tệp có kích thước tối đa 5MB")
                                            );
                                        },
                                    }
                                ]}
                                className='form-profile__upload__wrapper'
                            >
                                <Upload
                                    accept=".doc,.docx,.pdf"
                                    maxCount={1}
                                    beforeUpload={() => false}
                                    fileList={arrFile}
                                    onChange={(file) => {
                                        setArrFile(file.fileList);
                                    }}


                                >
                                    <Button className="form-profile__upload" size="large">
                                        Kéo sơ yếu lý lịch của bạn
                                        <UploadIcon />
                                    </Button>
                                </Upload>
                            </Form.Item>
                        </Col>
                        <Col xs={24} className="form-profile__input__wrapper">
                            <Button
                                htmlType="submit"
                                size="large"
                                className="form-profile__submit"
                                loading={loading}
                            >
                                NỘP ĐƠN
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </div>
        </section>
    )
}

export default FormProfile