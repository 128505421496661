const API_URL = process.env.REACT_APP_API_ENDPOINT;

enum ROUTE_PATHS {
	"Home" = "/",
	"SignIn" = "/sign-in",
	"SignUp" = "/sign-up",
	"ForgotPassword" = "forgot-password",
	"Profile" = "/profile",
	"Services" = "/services",
	"HRM" = "/HRM",
	"RecruitmentDetail" = `/HRM/recruitment/recruitment-detail/:id`,
	"ServicesOther" = "/services/services-other",
  	"AboutUs" = "/about-us",
	"Event" = "/event",
	Contact = "/contact",
	Partner = "/partner",
}

//this variable is using for params url
const ROUTE_DYNAMIC_VARIABLE_LIST = [":id"];

//this variable is using for replace params url with real data
enum ROUTE_DYNAMIC_VARIABLE {
  "id" = ":id",
}
export default API_URL;

export { ROUTE_PATHS, ROUTE_DYNAMIC_VARIABLE_LIST, ROUTE_DYNAMIC_VARIABLE };
