import { Pagination, PaginationProps, Row } from 'antd'
import JobItem from './JobItem'
import InfiniteScroll from 'react-infinite-scroll-component';
import './job.scss'
import logoJob from '../../assets/images/hrm/logo-job-1.png'
import { ICareer, IParamsJob } from '../../@types/job'
import { dispatch, useSelector } from '../../redux/store'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { getListCareer, getMoreListCareer } from '../../redux/slices/career'
import { DEFAULT_PAGINATION } from '../../constants/app-constants'
const DATA: any = [
  {
    id: 1,
    name: 'Merchandising Specialist (Growth)',
    logo: logoJob,
    level: 'Middle, Senior',
    location: "Đà Nẵng",
    type: "Toàn thời gian"
  },
  {
    id: 2,
    name: 'Merchandising Specialist (Growth)',
    logo: logoJob,
    level: 'Middle, Senior',
    location: "Đà Nẵng",
    type: "Toàn thời gian"
  }
  ,
  {
    id: 3,
    name: 'Merchandising Specialist (Growth)',
    logo: logoJob,
    level: 'Middle, Senior',
    location: "Đà Nẵng",
    type: "Toàn thời gian"
  }
]
type IProps = {
  params: IParamsJob;
  setParams: Dispatch<SetStateAction<IParamsJob>>;
}
const JobList = ({params, setParams}:IProps) => {
  const [isFetch, setIsFetch] = useState(true);

  const { listCareer, careerCount } = useSelector((state) => state.career);
  


  const getCareerList = async (options: IParamsJob) => {
    // setLoading(true);
    try {
      const res = await dispatch(getMoreListCareer(options));
    } finally {
      // setLoading(false);
    }
  };
  const fetchMoreData = () => {
    if(careerCount === listCareer.length) {
      setIsFetch(false);
      return;}
    const option = {...params, pageIndex: params.pageIndex + 1}
    setParams(option);
    getCareerList(option)
  };



  return (
    <>
    <InfiniteScroll
          dataLength={listCareer.length}
          next={fetchMoreData}
          hasMore={isFetch}
          loader={<></>}
        >
      <section className='job__list'>
        {listCareer.map((item) => <JobItem key={item.id} job={item} />)}

      </section>

        </InfiniteScroll>
      {/* <Row align={'middle'} justify={'center'}>
        <Pagination className='job__pagination' defaultPageSize={10} defaultCurrent={1} showSizeChanger={false} total={careerCount} onChange={onChange} />
      </Row> */}
    </>
  )
}

export default JobList