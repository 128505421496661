import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';


import {
  IServiceEventCreated,
  IServiceEventParams,
  IServiceEventState,
} from '../../@types/serviceEvent';
import ServiceEventApi from '../../services/serviceEvent.api';
import ObjectTypeApi from '../../services/objecType.api';

type IServiceEventCreatedAction = {
  data: IServiceEventCreated;
  navigate: (path: string) => void;
};
type IServiceEventDelete = {
  ids: number[] | string[];
  params: IServiceEventParams;
};
// ----------------------------------------------------------------------

export const getObjectTypeEvent = createAsyncThunk(
  'event/getListObjectType',
  async (params: any, { dispatch }) => {
    const { data } = await ObjectTypeApi.get({ objectType: params });
    return data;
  }
);

export const getListServiceEvent = createAsyncThunk(
  'event/getListEvent',
  async (params: IServiceEventParams, { dispatch }) => {
    const { data } = await ServiceEventApi.get(params);
    return data;
  }
);

export const getServiceIntroduce = createAsyncThunk(
  'event/introduce',
  async (params: string, { dispatch }) => {
    const { data } = await ObjectTypeApi.get({ objectType: params });
    return data;
  }
);

export const getServiceProcedure = createAsyncThunk(
  'event/procedure',
  async (params: string, { dispatch }) => {
    const { data } = await ObjectTypeApi.get({ objectType: params });
    return data;
  }
);



export const getServiceEventDetail = createAsyncThunk(
  'event/getEventDetail',
  async (id: number, { dispatch }) => {
    const { data } = await ServiceEventApi.getDetail(id);
    return data;
  }
);



const initialState: IServiceEventState = {
  serviceEventList: [],
  sericeEventCount: 0,
  serviceEventDetail: {
    id: 0,
    images: '',
  },
  serviceEventType: [],
  serviceIntroduce: {
    id: 0,
    objectCode: '',
    objectName: '',
    objectType: '',
  },
  serviceProcedure: {
    id: 0,
    objectCode: '',
    objectName: '',
    objectType: '',
  },
};

const slice = createSlice({
  name: 'Event',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getListServiceEvent.fulfilled, (state, action) => {
        state.serviceEventList = action.payload.items;
        state.sericeEventCount = action.payload.totalRow;
      })
      .addCase(getObjectTypeEvent.fulfilled, (state, action) => {
        state.serviceEventType = action.payload;
      })
      .addCase(getServiceEventDetail.fulfilled, (state, action) => {
        state.serviceEventDetail = action.payload;
      })
      .addCase(getServiceIntroduce.fulfilled, (state, action) => {
        state.serviceIntroduce = action.payload[0];
      })
      .addCase(getServiceProcedure.fulfilled, (state, action) => {
        state.serviceProcedure = action.payload[0];
      });
  },
});
export default slice.reducer;
