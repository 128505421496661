import Contact from '../../../pages/contact/Contact'
import Footer from '../layout/footer/Footer'
import './section.scss'
const SectionSeven = () => {
  return (
    <section className='sectionSeven'>
        <div className='sectionSeven__wrapper'>
            <Contact/>
        </div>
        <Footer isHome={true}/>
    </section>
  )
}

export default SectionSeven