import { Col, Row } from 'antd'
import sectionImage from '../../../assets/images/home/section-three.png'
import NextButton from '../../button/next-button/NextButton'
import { ROUTE_PATHS } from '../../../constants/url-config'
import { useSelector } from '../../../redux/store'
type IProps = {
  isActive: boolean
}

const SectionThree = ({ isActive }: IProps) => {
  const { listHomeSectionThree } = useSelector((state) => state.projectImage);
  const { images } = listHomeSectionThree
  const { homePage } = useSelector((state) => state.editWebsite);
  const sectionThreeContent = homePage?.sectionThree;
  return (
    <div className="sectionThree home-section">
      <Row className="sectionThree__container">
        <Col xs={24} sm={24} md={24} lg={18} xl={16} className='sectionThree__left'>
          <div className="sectionThree__left__image"
            style={{
              backgroundImage: `url(${sectionThreeContent?.banner})`,
            }}
          >
          </div>
        </Col>
        <div className="sectionTwo__item__divider"></div>

        <Col xs={24} sm={24} md={24}  lg={6}  xl={8}  className='sectionThree__right'>
          <span className={`sectionThree__right__title--mobile`}>tổ chức
            <br />sự kiện</span>
          <span className={`sectionThree__right__title ${isActive ? 'animate' : ''}`}>tổ chức
            <br />sự kiện</span>
          <div className='sectionTwo__item__nextButton'>
            <NextButton isPlusIcon link={ROUTE_PATHS.Event}/>
          </div>
          <span className='sectionThree__right__content'>

            {sectionThreeContent?.content}
          </span>

        </Col>
      </Row>
    </div>
  )
}

export default SectionThree