import { IServiceEventCreated, IServiceEventParams } from '../@types/serviceEvent';
import { deleteAsync, getAsync, postAsync } from './http-client';

const url = 'product';
const ServiceEventApi = {
  get: (data: IServiceEventParams) => {
    return getAsync(`/${url}`, data);
  },
  getDetail: (id: number) => {
    return getAsync(`/${url}/${id}`);
  },
};

export default ServiceEventApi;
