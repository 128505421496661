import { Col, Collapse, Row, Space } from 'antd'
import './Recruitment.scss'
import { SearchIcon } from '../../../icons'
import CollapseFilter from '../../../collapse/CollapseFilter'
import CollapseAllFilter from '../../../collapse/CollapseAllFilter'
import { useEffect, useState } from 'react'
import { CheckboxValueType } from 'antd/lib/checkbox/Group'
import Job from '../../../job/Job'
import JobDetail from '../../../job/job-detail/JobDetail'
import { dispatch, useSelector } from '../../../../redux/store'
import { getProvinceList } from '../../../../redux/slices/province'
import { DEFAULT_PAGINATION, OBJECT_TYPE } from '../../../../constants/app-constants'
import { getListCareer } from '../../../../redux/slices/career'
import { IParamsJob } from '../../../../@types/job'

// const optionsLocation = [
//   {
//     label: 'Đà Nẵng',
//     value: 'Đà Nẵng'
//   },
//   {
//     label: 'Hồ Chí Minh',
//     value: 'Hồ Chí Minh'
//   },
//   {
//     label: 'Hà Nội',
//     value: 'Hà Nội'
//   }
// ]
const optionsJobLevel = [
  {
    label: 'Fresher',
    value: 'Fresher'
  },
  {
    label: 'Junior',
    value: 'Junior'
  },
  {
    label: 'Middle',
    value: 'Middle'
  },
  {
    label: 'Senior',
    value: 'Senior'
  },
]
const optionsWorkMethod = [
  {
    label: 'Remote',
    value: 'remote'
  },
  {
    label: 'Hybrid',
    value: 'hybrid'
  },
  {
    label: 'Tại văn phòng',
    value: 'office'
  },
]
type IProps = {
  id?: string | number;
  type: number;
}
const Recruitment = ({ id, type }: IProps) => {
  const isCommingSoon = type === OBJECT_TYPE.recruitment.hrm || type === OBJECT_TYPE.recruitment.managementSystem;
  const [inputValue, setInputValue] = useState("");
  const [listJobLevel, setListJobLevel] = useState<CheckboxValueType[]>([]);
  const [listTypeWork, setListTypeWork] = useState<CheckboxValueType[]>([]);
  const [listProvinceId, setListProvinceId] = useState<CheckboxValueType[]>([]);
  const paramsDefault = {
    pageIndex: DEFAULT_PAGINATION.PAGE_INDEX,
    pageSize: DEFAULT_PAGINATION.PAGE_SIZE,
    keyword: '',
    // brand_id: type,
  }
  const [params, setParams] = useState<IParamsJob>(paramsDefault);
  const [isDetail, setIsDetail] = useState<boolean>(false);
  const [totalImage, setTotalImage] = useState<number>(0);
  const [debouncedInputValue, setDebouncedInputValue] = useState("");

  const { provinceList } = useSelector((state) => state.province);

  // convert provinceList to optionsLocation
  const optionsLocation = provinceList.map((item) => {
    return {
      label: item.name ?? "",
      value: item.id ?? ""
    }
  })
  //
  const handleInputChange = (event: any) => {
    setInputValue(event.target.value);
  };

  const calcTotalFilterHandle = (lengthList: number) => {
    if (!lengthList && !totalImage) return;
    lengthList ? setTotalImage(totalImage + 1) : setTotalImage(totalImage - 1);

  }

  const changeListSelectedHandle = (checkedValues: CheckboxValueType[]) => {
    if (listProvinceId.length === 0 || checkedValues.length === 0) {
      calcTotalFilterHandle(checkedValues.length)
    }
    setListProvinceId(checkedValues);
  }

  const filterListJobHandle = (checkedValues: CheckboxValueType[]) => {
    if (listJobLevel.length === 0 || checkedValues.length === 0) {
      calcTotalFilterHandle(checkedValues.length)
    }
    setListJobLevel(checkedValues);
  }

  const filterTypeWorking = (checkedValues: CheckboxValueType[]) => {
    if (listTypeWork.length === 0 || checkedValues.length === 0) {
      calcTotalFilterHandle(checkedValues.length)
    }
    setListTypeWork(checkedValues);
  }

  const resetFilterHandle = () => {
    setParams(paramsDefault);
    setListJobLevel(prev => []);
    setListProvinceId(prev => []);
    setListTypeWork(prev => []);
    setTotalImage(0);
  }


  const filterCareerListHandle = async (options: IParamsJob) => {
    await dispatch(getListCareer({
      ...options,
      keyword: debouncedInputValue,
      level: listJobLevel.join(','),
      province_id: listProvinceId.join(','),
      workingType: listTypeWork.join(','),
    }));
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDebouncedInputValue(inputValue);
    }, 500);
    return () => clearTimeout(timeoutId);
  }, [inputValue, 500]);

  useEffect(() => {
    if (id) { setIsDetail(true); return; }
    setIsDetail(false)
  }, [id])

  useEffect(() => {

    if (!provinceList.length) {
      dispatch(
        getProvinceList({
          pageSize: DEFAULT_PAGINATION.GET_ALL,
          pageIndex: DEFAULT_PAGINATION.PAGE_INDEX,
        })
      );

    }
  }, []);

  useEffect(() => {
    filterCareerListHandle(params);
  }, [listJobLevel, listProvinceId, listTypeWork, debouncedInputValue])


  return (
    <section className='recruitment '>
      <div className='recruitment__container'>
        {!isDetail ?
          (isCommingSoon
            ?
            <div className='job__commingsoon'>
              <h5 >Sắp ra mắt</h5></div>
            :
            <Row >

              <Col xs={0} lg={7} xl={6}>
                <Row>
                  <Col lg={12} xl={12}>
                    <span className="recruitment__filter__text">
                      Bộ lọc ({totalImage})
                    </span>
                  </Col>
                  <Col lg={12} xl={12} style={{ textAlign: "right" }}>
                    <span className="recruitment__filter__clear" onClick={resetFilterHandle}>
                      Xoá bộ lọc
                    </span>
                  </Col>
                </Row>
                <Row className='recruitment__filter__container'>
                  <Col lg={24} xl={24}>
                    <span className="recruitment__filter__text">
                      Tìm công việc yêu thích của bạn
                    </span>
                  </Col>
                  <Col lg={24} xl={24} style={{ textAlign: "right" }}>
                    <Row className='recruitment__filter__input__container'>
                      {/* icon */}
                      <SearchIcon className="recruitment__filter__input__icon" />
                      <input placeholder='Tất cả công việc' className="recruitment__filter__input__text-search" onChange={handleInputChange} />

                      {/* input */}
                    </Row>
                  </Col>
                </Row>
                <div className='recruitment__divider'></div>
                {/*List filter  */}
                <div className='recruitment__collapse-filter__container'>
                  <CollapseFilter header='Vị trí' options={optionsLocation} listSelected={listProvinceId} onChange={(e) => changeListSelectedHandle(e)
                  } />
                </div>
                <div className='recruitment__divider'></div>
                <div className='recruitment__collapse-filter__container'>
                  <CollapseAllFilter header='Cấp độ công việc' options={optionsJobLevel} listSelected={listJobLevel} onChangeListSelected={filterListJobHandle} />
                </div>
                <div className='recruitment__divider'></div>
                <div className='recruitment__collapse-filter__container'>
                  <CollapseFilter header='Phương thức làm việc' options={optionsWorkMethod} listSelected={listTypeWork} onChange={filterTypeWorking} />
                </div>
                <div className='recruitment__divider'></div>

              </Col>
              <Col xs={24} lg={17} xl={18} >
                <Job params = {params} setParams={setParams}/>
              </Col>
            </Row>)
          :
          <JobDetail />
        }

      </div>
    </section>
  )
}

export default Recruitment