import { Col, Row } from "antd";
import { useSelector } from "../../../redux/store";

const SectionFiveMobile = () => {
  const { homePage } = useSelector((state) => state.editWebsite);
  const sectionFiveContent = homePage?.sectionFive;
  return (
    <div className="sectionFiveMobile">
      <Row
        style={{ flexDirection: "row-reverse" }}
        className="sectionFiveMobile__container"
      >
        <Col
          xs={24}
          md={24}
          sm={24}
          lg={10}
          xl={8}
          className="sectionFiveMobile__left__wrapper"
        >
          <Row style={{ height: "100%" }}>
            <Col xs={24} style={{ height: "100%" }}>
              <div className="sectionFiveMobile__left__container">
                <div className="sectionFiveMobile__left__title">
                  <span>{sectionFiveContent?.title}</span>
                </div>
                <div className="sectionFiveMobile__left__subtitle">
                  <span>{sectionFiveContent?.subtitle}</span>
                </div>
              </div>
            </Col>
          </Row>
        </Col>
        <Col
          xs={24}
          md={24}
          sm={24}
          lg={14}
          xl={16}
          className="sectionFiveMobile__right__wrapper"
        >
          <div className="sectionFiveMobile__right__container">
            <div
              className="sectionFiveMobile__right__image"
              style={{
                backgroundImage: `url(${sectionFiveContent?.banner})`,
              }}
            ></div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default SectionFiveMobile;
