import { Button, Col, DatePicker, Form, Input, Row, Select, Upload } from 'antd';
import './form-contact.scss'
import { useState } from 'react';
import { ArrowRightIcon, UploadIcon } from '../../icons';
import { RcFile } from 'antd/lib/upload';
import TextArea from 'antd/lib/input/TextArea';
import { IContact } from '../../../@types/contact';
import { dispatch } from '../../../redux/store';
import { createContact } from '../../../redux/slices/contact';
import { useLocation } from 'react-router';
import { ROUTE_PATHS } from '../../../constants/url-config';
import Utils from '../../../utils/utils';
const FormContact = () => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);


    const location = useLocation();
    const { pathname } = location;
    const isPage = pathname === ROUTE_PATHS.Contact;

    const onFinish = async (data: IContact) => {
        setLoading(true);
        try {
          const submitData = {
            ...data,
          };
          await dispatch(createContact(submitData));
          Utils.openNotification("success", "Gửi tin nhắn thành công");
        } 
        catch (error) {
          Utils.openNotification("error", "Gửi tin nhắn thất bại");
        }
        finally {
          setLoading(false);
          form.resetFields();
        }
    };
    // Custom validation rule for email
  const validateEmail = (_:any, value:string) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (value && !emailRegex.test(value)) {
      return Promise.reject('Please enter a valid email!');
    }
    return Promise.resolve();
  };

    return (
        <section className="form-contact">
            <div className='form-contact__container'>
                <Form form={form} name="formJob" onFinish={onFinish}>
                    <Row className="form-contact__wrapper">
                        
                        <Col xs={24} className="form-contact__input__wrapper">
                            <div className="form-contact__label">
                                Họ và Tên
                                <span className='required'>*</span>
                            </div>
                            <Form.Item
                                name="fullName"
                                rules={[
                                    { required: true, message: "Vui lòng nhập họ và tên" },
                                ]}
                            >
                                <Input
                                    className={`form-contact__input ${isPage && 'form-contact__input--page'}`}
                                    placeholder="Họ và tên"
                                />
                            </Form.Item>
                        </Col>

                        <Col xs={24} className="form-contact__input__wrapper">
                            <div className="form-contact__label">
                                Email
                                <span className='required'>*</span>
                            </div>
                            <Form.Item
                                name="email"
                                rules={[
                                    { required: true, message: "Vui lòng nhập email" },
                                   { validator: validateEmail, message: 'Email không hợp lệ' },
                                ]}
                            >
                                <Input
                                    className={`form-contact__input ${isPage && 'form-contact__input--page'}`}
                                    placeholder="hunglong@gmail.com"
                                />
                            </Form.Item>
                        </Col>

                        <Col xs={24} className="form-contact__input__wrapper">
                            <div className="form-contact__label">
                                Nội dung
                            </div>
                            <Form.Item
                                name="note"
                                
                            >
                                <TextArea
                                    className={`form-contact__input ${isPage && 'form-contact__input--page'}`}
                                    placeholder="Nếu bạn có bất kỳ câu hỏi hoặc cần bất kỳ sự trợ giúp nào"
                                    rows={4}
                                   
                                />
                            </Form.Item>
                        </Col>

                        <Col xs={24} className="form-contact__input__wrapper">
                            <Button
                                htmlType="submit"
                                size="large"
                                className="form-contact__submit"
                                loading={loading}
                            >
                                GỬI TIN NHẮN
                                <ArrowRightIcon />
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </div>
        </section>
    )
}

export default FormContact