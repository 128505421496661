import ReactS3Client from "react-aws-s3-typescript";
import { S3_PROJECT } from "../constants/app-constants";
import { notification } from "antd";

const Utils = {
    formatVN(str: string) {
        str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
        str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
        str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
        str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
        str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
        str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
        str = str.replace(/đ/g, "d");
        str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, "A");
        str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, "E");
        str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, "I");
        str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, "O");
        str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, "U");
        str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, "Y");
        str = str.replace(/Đ/g, "D");
        str = str.replace(/\s/g, "");
        return str;
    },
    localStorageAvailable() {
        try {
            // Incognito mode might reject access to the localStorage for security reasons.
            // window isn't defined on Node.js
            // https://stackoverflow.com/questions/16427636/check-if-localstorage-is-available
            const key = '__some_random_key_you_are_not_going_to_use__';
            window.localStorage.setItem(key, key);

            window.localStorage.removeItem(key);

            return true;
        } catch (err) {
            return false;
        }
    },
    async uploadFile(file: any, dirName: string, fileNameUpload?: any) {
        const fileName = `${Date.now()}-${Utils.formatVN(
            file?.name
        )}-${fileNameUpload}`;

        const config = {
            bucketName: 'hunglong',
            dirName: `${S3_PROJECT}/${dirName}`,
            region: 'ap-southeast-1',
            accessKeyId: 'AKIATHIGM7KOLR3C4BML',
            secretAccessKey: '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',
        };

        const s3 = new ReactS3Client(config);

        try {
            const res = await s3.uploadFile(file, fileName);
            return res.location;
        } catch (err) {
            return null;
        }
    },
    openNotification: (type: NotificationType, message: string,) => {
        notification[type]({
            message,

        });
    }
};

export default Utils;

type NotificationType = 'success' | 'info' | 'warning' | 'error';
 