



const SearchIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
  <path d="M23.696 23.0608L23.6692 23.034C22.3297 21.6948 20.9902 20.3556 19.6507 19.0164C19.3519 18.7191 19.0519 18.4231 18.7478 18.1232L18.1276 17.5111L18.291 17.3223C22.0417 12.9632 21.6894 6.56041 17.4873 2.745C13.3523 -1.00479 7.19187 -0.901677 3.15458 2.98605C-0.729995 6.73584 -1.06621 12.9337 2.40178 17.1027C3.28928 18.1747 4.38326 19.0574 5.61873 19.6983C6.8542 20.3393 8.20593 20.7254 9.5936 20.8337C10.9948 20.947 12.4045 20.7777 13.7391 20.336C15.0737 19.8944 16.3059 19.1892 17.3628 18.2624L17.5865 18.0696L17.7566 18.3107C17.7807 18.3441 17.8021 18.3789 17.8249 18.4138C17.8577 18.4688 17.8953 18.5208 17.9374 18.5691C19.6011 20.2378 21.2674 21.9042 22.9365 23.5684L23.0838 23.717C23.1475 23.7835 23.215 23.8461 23.2861 23.9045C23.3692 23.9731 23.4746 24.0086 23.5822 24.0043C23.6898 24.0001 23.7921 23.9563 23.8695 23.8814C23.9469 23.8065 23.994 23.7058 24.0018 23.5984C24.0095 23.491 23.9774 23.3845 23.9116 23.2992C23.8461 23.2142 23.774 23.1345 23.696 23.0608ZM10.4549 19.9806H10.4402C7.91037 19.9806 5.48417 18.9759 3.69533 17.1875C1.90648 15.399 0.901519 12.9734 0.901519 10.4441C0.901519 7.91488 1.90648 5.48923 3.69533 3.70079C5.48417 1.91234 7.91037 0.907602 10.4402 0.907602C12.97 0.906359 15.3967 1.9099 17.1864 3.69747C18.9761 5.48503 19.9823 7.91019 19.9835 10.4394C19.9848 12.9687 18.981 15.3948 17.193 17.1842C15.4051 18.9735 12.9794 19.9794 10.4496 19.9806H10.4549Z" fill="white"/>
</svg>
)

const MenuOpen = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
  <line y1="13.5" x2="48" y2="13.5" stroke="white"/>
  <line y1="23.5" x2="48" y2="23.5" stroke="white"/>
  <line y1="33.5" x2="48" y2="33.5" stroke="white"/>
</svg>
)

const MenuClose =() => (
    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
  <path d="M4 4L44 44" stroke="white" stroke-width="0.821485" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M44.0002 4L4.00028 44" stroke="white" stroke-width="0.821485" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
)

const DropDownIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
  <path d="M14.9288 4.5H13.5895C13.4984 4.5 13.4127 4.53418 13.3592 4.59023L8.28594 9.94414L3.21273 4.59023C3.15915 4.53418 3.07344 4.5 2.98237 4.5H1.64308C1.52701 4.5 1.45915 4.60117 1.52701 4.67363L7.82344 11.3195C8.05201 11.5602 8.51987 11.5602 8.74665 11.3195L15.0431 4.67363C15.1127 4.60117 15.0449 4.5 14.9288 4.5Z" fill="white"/>
</svg>
)

const ArrowRightIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
  <path d="M14.9288 4.5H13.5895C13.4984 4.5 13.4127 4.53418 13.3592 4.59023L8.28594 9.94414L3.21273 4.59023C3.15915 4.53418 3.07344 4.5 2.98237 4.5H1.64308C1.52701 4.5 1.45915 4.60117 1.52701 4.67363L7.82344 11.3195C8.05201 11.5602 8.51987 11.5602 8.74665 11.3195L15.0431 4.67363C15.1127 4.60117 15.0449 4.5 14.9288 4.5Z" fill="white"/>
</svg>
)

const SVGIcons = {
    SearchIcon,
    MenuOpen,
    MenuClose,
    DropDownIcon,
    ArrowRightIcon
}

export default SVGIcons;


