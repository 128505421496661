import { Button, Col, Modal, Row } from "antd";
import "./Content.scss";
import { useState } from "react";
import useResponsive from "../../../../../hooks/useResponsive";
import { ModalCloseIcon } from "../../../../icons";

type Props = {
  title: string;
  desc: string;
  time: string;
  images: string[];
  thumbnail?: string;
};

const ContentComponent = ({
  title,
  desc,
  time,
  images = [],
  thumbnail,
}: Props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalImage, setModalImage] = useState<string | null>(null);

  const { isMobile } = useResponsive();

  const showModal = () => {
    setIsModalOpen(true);
    if(images[0]){
      setModalImage(images[0]);

    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleThumbnailClick = (imageSrc: string) => {
    setModalImage(imageSrc);
    // showModal();
  };
  const currentIndex = modalImage ? images.indexOf(modalImage) : -1;
  return (
    <>
      <div className="content">
        <p className="content__title">{title}</p>
        <h5 className="content__desc">{desc}</h5>
        <Button
          className={`content__button ${
            isMobile ? "content__button__mobile" : ""
          }`}
          onClick={showModal}
        >
          <span className="content__button--title">XEM CHI TIẾT</span>
        </Button>
        <p className="content__time">{time}</p>
      </div>

      <Modal
        centered={true}
        title={`${currentIndex + 1}/${images.length} hình ảnh`}
        open={isModalOpen} 
        footer={null}
        onCancel={handleCancel}
        className="content__modal"
        closeIcon={<ModalCloseIcon/>}
      >
        {modalImage ? (
          <img className="content__modal__img" src={modalImage} alt="" />
        ) : (
          <img
            className="content__modal__img"
            src={thumbnail ?? ""} 
            alt=""
          />
        )}
        <Row  className="content__modal__tabs">
          <Col
            className={`scroll__custom ${
              images.length < 6 ? "no__scroll" : ""
            }`}
          >
            {Array.from({ length: images.length }).map((_, index) => (
              <p className="scroll__custom__item" key={index}>
                <img
                  className="scroll__custom__item--img"
                  src={images[index % images.length]}
                  alt={`Image ${index}`}
                  onClick={() =>
                    handleThumbnailClick(images[index % images.length])
                  }
                />
              </p>
            ))}
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default ContentComponent;
