import { ParamsType } from "../@types/paramsType";
import { getAsync } from "./http-client";


const ProductApi = {
  get: (data: ParamsType) => {
    return getAsync(`/product`, data);
  },
  getDetail: (id: number | string) => {
    return getAsync(`/product/${id}`);
  },
};

export default ProductApi;
