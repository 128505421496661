import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';
import productReducer from "./slices/otherService"
import headerReducer from "./slices/header"
import careerReducer from "./slices/career"
import provinceReducer from './slices/province'
import applyCareersReducer from './slices/apply-careers'
import contactReducer from './slices/contact'
import otherServiceReducer from './slices/otherService'
import projectImageReducer from './slices/projectImage'
import serviceReducer from './slices/service'
import serviceEventReducer from './slices/serviceEvent'
import editWebsiteReducer from './slices/edit-website'


// ----------------------------------------------------------------------

export const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

export const productPersistConfig = {
  key: 'product',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy', 'checkout'],
};

const rootReducer = combineReducers({
  product: productReducer,
  header: headerReducer,
  career: careerReducer,
  province: provinceReducer,
  applyCareers: applyCareersReducer,
  contact: contactReducer,
  otherService: otherServiceReducer,
  projectImage: projectImageReducer,
  serviceEvent: serviceEventReducer,
  service: serviceReducer,
  editWebsite: editWebsiteReducer,

});

export default rootReducer;
