import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect, useState } from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import { BrowserRouter, Redirect, Switch } from 'react-router-dom';
import { PersistGate } from 'redux-persist/lib/integration/react';
import './App.scss';
import Loading from './components/atoms/loading/loading';
import Notification from './components/atoms/notification/notification';
import RouteLayout from './components/views/layout/route-layout';
import { ROUTE_PATHS } from './constants/url-config';
import { AuthProvider } from './contexts/JwtContext';
import { persistor, store } from './redux/store';
import { routes } from './routing';
// import Scroll from './pages/scroll/Scroll';


function App() {
	const [isBright, setIsBright] = useState(true);
	
	
	useEffect(() => {
		const timeout = setTimeout(() => {
			setIsBright(false);
		}, 1000);

		return () => clearTimeout(timeout);
	}, []);
	
	

	useEffect(() => {
		AOS.init({
			mirror: true,
		})
	}, []);

	return (
		<div
			className={`App ${!isBright ? 'bright' : 'dim'}`}
		>
			<AuthProvider>
				<ReduxProvider store={store}>
					<PersistGate loading={null} persistor={persistor}>
						<Loading />
						<Notification />
						<BrowserRouter>
							<Switch>
								{routes.map(({ href, exact, component, permissions, loginRequired }) => (
									<RouteLayout
										key={href}
										path={href}
										exact={exact}
										component={component}
										permissions={permissions}
										loginRequired={loginRequired}
									/>
								))}
								<Redirect to={ROUTE_PATHS.SignIn} />
							</Switch>
						</BrowserRouter>
					</PersistGate>
				</ReduxProvider>
			</AuthProvider>
		</div>
	)
}

export default App;
