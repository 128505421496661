
const NextButtonIcon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="605be3411de7f80a75187d66_arrow-down(24x24)%402x.svg">
                <path id="Vector" d="M21 12L3 12" stroke="#ED1B24" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path id="Vector_2" d="M15 18L21 12L15 6" stroke="#ED1B24" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            </g>
        </svg>

    )
}

export default NextButtonIcon