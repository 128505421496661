import { AxiosResponse } from "axios";
import { postAsync } from "./http-client";
import { IContact } from "../@types/contact";

const ContactApi = {
    post: async (params: IContact): Promise<AxiosResponse> => {
        return await postAsync('/contact', params)
    }
}

export default ContactApi;
