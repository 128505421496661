import { Col, Row } from "antd";
import "./contact.scss";
import { FacebookIcon, InstagramIcon, ZaloIcon } from "../../components/icons";
import recognize from "../../assets/images/home/recognize.png";
import FormContact from "../../components/form/form-contact/FormContact";
import { useLocation } from "react-router";
import { ROUTE_PATHS } from "../../constants/url-config";
import { dispatch, useSelector } from "../../redux/store";
import { useEffect } from "react";
import { getContentWebsite } from "../../redux/slices/edit-website";
import { WebcontentType } from "../../constants/app-constants";
const Contact = () => {
  const location = useLocation();
  const { pathname } = location;
  const isPage = pathname === ROUTE_PATHS.Contact;
  const { homePage } = useSelector((state) => state.editWebsite);
  const contact = homePage?.contact;
  useEffect(() => {
    if (!homePage) {
      dispatch(getContentWebsite(WebcontentType.Home));
    } 
  }, [homePage]);
  return (
    <section className={`contact ${isPage && "contact__page"}`}>
      <Row style={{ height: "100%" }}>
        <Col xs={24} lg={12} xl={12}>
          <div className="contact__left__container">
            <h2
              className={`contact__title ${isPage && "contact__title--page"}`}
            >
              LIÊN HỆ
            </h2>
            <div className="contact__subtitle">
              <span>Liên hệ với chúng tôi để thảo luận về sự án của bạn</span>
            </div>
            <Row>
              <Col xs={24} md={0} lg={0} xl={0}>
                <div className="contact__right__container">
                  <FormContact />
                </div>
              </Col>
              <Col
                xs={24}
                md={24}
                lg={24}
                xl={24}
                className={`contact__right__container--mobile ${
                  isPage && "contact__right__container--page"
                }`}
              >
                <Row>
                  <Col xs={24} lg={24} xl={12}>
                    <div className="contact__info__container">
                      <span className="contact__info__label">MAIL:</span>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={`https://mail.google.com/mail/u/0/?fs=1&to=contact@hunglongbranding.com&tf=cm`}
                      >
                        <p className="contact__info__content contact__info__content--link">
                          {contact?.mail}
                        </p>
                      </a>
                    </div>
                  </Col>
                  <Col xs={24} lg={24} xl={12}>
                    <div className="contact__info__container">
                      <span className="contact__info__label">PHONE:</span>
                      <a
                        href={`tel:0345.499.788`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <p
                          style={{ fontWeight: "bold" }}
                          className="contact__info__content contact__info__content--phone contact__info__content--link"
                        >
                          {contact?.phone}
                        </p>
                      </a>
                    </div>
                  </Col>
                </Row>
                <div className="contact__info__container">
                  <span className="contact__info__label">Địa chỉ:</span>
                  <p className="contact__info__content">{contact?.address}</p>
                </div>
                <div className="contact__info__container">
                  <span className="contact__info__label">Địa chỉ xưởng:</span>
                  <p className="contact__info__content">
                    {contact?.factoryAddress}
                  </p>
                </div>
                <Row align={"middle"} className="contact__social__container">
                  <Col lg={4} xl={3}>
                    <span className="contact__social__label">Follow us:</span>
                  </Col>
                  <Col lg={14} xl={12}>
                    <div className="contact__social__icon__container">
                      <a href={`${contact?.linkFacebook}`} target="blank">
                        <div className="contact__social__icon">
                          <FacebookIcon />
                        </div>
                      </a>
                      <a href={`${contact?.linkInstagram}`} target="blank">
                        <div className="contact__social__icon">
                          <InstagramIcon />
                        </div>
                      </a>
                      <a href={`${contact?.linkZalo}`} target="blank">
                        <div className="contact__social__icon contact__social__icon--zalo">
                          <ZaloIcon />
                        </div>
                      </a>
                      <div className="contact__recognize"></div>
                    </div>
                  </Col>
                  <Col lg={6} xl={7}>
                    <img src={recognize} />
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </Col>
        <Col xs={0} lg={12} xl={12}>
          <div className="contact__right__container">
            <FormContact />
          </div>
        </Col>
      </Row>
    </section>
  );
};

export default Contact;
