import { Col, Row } from 'antd'
import sectionImage from '../../../assets/images/home/section-three.png'
import NextButton from '../../button/next-button/NextButton'
import { useSelector } from '../../../redux/store';


const SectionThreeMobile = () => {

  const { homePage } = useSelector((state) => state.editWebsite);
  const sectionThreeContent = homePage?.sectionThree;
  return (
    <div className="sectionThreeMobile ">
      <Row className="sectionThreeMobile__container">
         <Col xs={24} sm={24} md={24} lg={6} xl={8} className='sectionThreeMobile__right home-section'>
        <Row align={'middle'}>
          <Col xs={12}>

          <span className={`sectionThreeMobile__right__title }`}>tổ chức
            <br />sự kiện</span>
          </Col>
           <Col xs={12}>
          <div className='sectionTwoMobile__item__nextButton'>
            <NextButton isPlusIcon />
          </div>
            
          </Col>

        </Row>
          <span className='sectionThreeMobile__right__content'>
            {sectionThreeContent?.content}
            </span>
        </Col>
        <Col xs={24} sm={24} md={24} lg={18} xl={16} className='sectionThreeMobile__left'>
          <div className="sectionThreeMobile__left__image"
            style={{
              backgroundImage: `url(${sectionThreeContent?.banner})`,
            }}
          >
          </div>
        </Col>
        <div className="sectionTwo__item__divider"></div>

       
      </Row>
    </div>
  )
}

export default SectionThreeMobile