
import { SVGProps } from 'react';
const SearchIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg {...props} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Layer_1" clipPath="url(#clip0_1382_6481)">
        <path id="Vector" d="M19.7466 19.2155L19.7243 19.1932C18.6081 18.0772 17.4918 16.9612 16.3755 15.8451C16.1266 15.5974 15.8766 15.3508 15.6232 15.1008L15.1064 14.5908L15.2425 14.4334C18.3681 10.8008 18.0745 5.46515 14.5728 2.28563C11.1269 -0.839191 5.99322 -0.753259 2.62882 2.48652C-0.608329 5.61134 -0.88851 10.7762 2.00148 14.2504C2.74107 15.1437 3.65272 15.8793 4.68228 16.4134C5.71183 16.9475 6.83827 17.2693 7.99467 17.3596C9.16235 17.4539 10.3371 17.3129 11.4492 16.9448C12.5614 16.5768 13.5883 15.9891 14.469 15.2168L14.6554 15.0561L14.7972 15.257C14.8172 15.2849 14.8351 15.3139 14.8541 15.3429C14.8814 15.3888 14.9128 15.4321 14.9478 15.4724C16.3342 16.863 17.7229 18.2516 19.1137 19.6385L19.2365 19.7623C19.2895 19.8177 19.3458 19.8699 19.4051 19.9186C19.4743 19.9757 19.5621 20.0053 19.6518 20.0018C19.7415 19.9982 19.8268 19.9617 19.8913 19.8993C19.9558 19.8369 19.995 19.7529 20.0015 19.6634C20.0079 19.5739 19.9812 19.4852 19.9264 19.4142C19.8717 19.3433 19.8117 19.2769 19.7466 19.2155ZM8.71243 16.6487H8.70015C6.59198 16.6487 4.57014 15.8114 3.07944 14.321C1.58874 12.8307 0.751266 10.8093 0.751266 8.70157C0.751266 6.59387 1.58874 4.5725 3.07944 3.08213C4.57014 1.59176 6.59198 0.754474 8.70015 0.754474C10.8083 0.753438 12.8306 1.58972 14.322 3.07936C15.8134 4.569 16.6519 6.58997 16.6529 8.69767C16.654 10.8054 15.8175 12.8272 14.3275 14.3183C12.8376 15.8094 10.8161 16.6476 8.70796 16.6487H8.71243Z" fill="black" />
      </g>
      <defs>
        <clipPath id="clip0_1382_6481">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>

  )
}

export default SearchIcon