import { Carousel, Col, Progress, Row } from "antd";
import pic1 from "../../assets/images/about/pic1.png";
import pic2 from "../../assets/images/about/pic2.png";
import pic3 from "../../assets/images/about/pic3.png";
import pic4 from "../../assets/images/about/pic4.png";
import pic5 from "../../assets/images/about/pic5.png";
import { NextButtonIcon } from "../../components/icons";
import "./about.scss";

import map from "../../assets/images/about/vietnam_map.svg";

import { Link } from "react-router-dom";
import { ROUTE_PATHS } from "../../constants/url-config";
import useResponsive from "../../hooks/useResponsive";
import { dispatch, useSelector } from "../../redux/store";
import { useEffect } from "react";
import { getContentWebsite } from "../../redux/slices/edit-website";
import { WebcontentType } from "../../constants/app-constants";

type Props = {};

const AboutUsPage = (props: Props) => {
  const { backgroundAboutUsImage, listActivityPartnerImage } = useSelector(
    (state) => state.projectImage
  );
  const { images } = backgroundAboutUsImage;
  const imagesActive = listActivityPartnerImage.images;
  const { aboutPage } = useSelector((state) => state.editWebsite);
  const { isMobile } = useResponsive();
  const progressItem = [
    {
      label: "THIẾT KẾ VÀ THI CÔNG",
      value: aboutPage?.sectionOne?.designAndConstruction,
    },
    {
      label: "TỔ CHỨC SỰ KIỆN",
      value: aboutPage?.sectionOne?.eventOrganization,
    },
    {
      label: "QUẢN LÝ NHÂN SỰ",
      value: aboutPage?.sectionOne?.hrm,
    },
  ];
  const renderProgress = () => {
    return progressItem?.map((item, index) => {
      return (
        <div className="progress-item" key={index}>
          <Progress
            strokeLinecap="butt"
            percent={item.value}
            showInfo={false}
            strokeColor="#ED1B24"
            trailColor="rgba(243, 246, 252, 0.5)"
          />
          <div className="progress-item__name">{item.label}</div>
          <div className="progress-item__percent">{`${item.value}%`}</div>
        </div>
      );
    });
  };

  useEffect(() => {
    if (!aboutPage) {
      dispatch(getContentWebsite(WebcontentType.AboutUs));
    }
  }, [aboutPage]);
  return (
    <div className="containerCustom about-us">
      <Row className="about-top sectionContainer" gutter={[25, 0]}>
        <Col xs={24} xl={12}>
          <div className="about-left">
            <div className="about-left-image">
              <div className="about-left-image-big">
                <img src={aboutPage?.sectionOne?.bannerLeft} alt="Image" />
              </div>
              <div className="about-left-image-small">
                <img src={aboutPage?.sectionOne?.bannerRight} alt="Image" />
              </div>
            </div>
          </div>
        </Col>
        <Col xs={24} xl={12}>
          <div className="about-right">
            <div className="about-right__title">
              {aboutPage?.sectionOne?.title}
            </div>
            <div className="about-right__subtitle">
              {aboutPage?.sectionOne?.subtitle}
            </div>
            <div className="progress-wrapper">{renderProgress()}</div>

            <Link
              to={ROUTE_PATHS.Contact}
              className="form-contact__submit button-wrapper"
            >
              Gửi tin nhắn
              <NextButtonIcon />
            </Link>
          </div>
        </Col>
      </Row>
      <Row className="about-bottom sectionContainer" gutter={[25, 0]}>
        <Col xs={24} xl={12}>
          <div className="about-bottom-left">
            <div className="vision-wrapper">
              <h3 className="vision__title">{aboutPage?.sectionTwo?.title}</h3>
              <div className="vision__subtitle">
                {aboutPage?.sectionTwo?.subtitle}
              </div>
            </div>
            <hr className="about-line" />
            <div className="activity-wrapper">
              <h3 className="activity__title">Hoạt động của chúng tôi</h3>
              <ul className="activity__list">
                <p>Toàn quốc, trong đó:</p>
                {aboutPage?.sectionTwo?.activity.listActivity.map((item) => {
                  return <li>{item.value}</li>;
                })}
              </ul>
            </div>
            {isMobile ? (
              <div className="about-bottom-right">
                <img src={aboutPage?.sectionTwo?.activity.imageMap} alt="Map" />
              </div>
            ) : (
              <Row className="activity-image" gutter={[40, 0]}>
                {aboutPage?.sectionTwo?.activity.listImageActivity?.map(
                  (item) => {
                    return (
                      <Col xs={8}>
                        <div className="activity-image-item">
                          <img src={item} alt="images" />
                        </div>
                      </Col>
                    );
                  }
                )}
              </Row>
            )}
          </div>
        </Col>
        <Col xs={24} xl={12} style={{ padding: 0 }}>
          {isMobile ? (
            <Row
              className="activity-image"
              // gutter={[40, 0]}
            >
              <Carousel
                autoplaySpeed={1500}
                dots={false}
                className={`carouselCustom `}
                slidesToShow={2.5}
                autoplay
                infinite
              >
                {aboutPage?.sectionTwo?.activity.listImageActivity?.map(
                  (item) => {
                    return (
                      <Col xs={24}>
                        <div className="activity-image-item">
                          <img src={item} alt="images" />
                        </div>
                      </Col>
                    );
                  }
                )}
              </Carousel>
            </Row>
          ) : (
            <div className="about-bottom-right">
              <img src={aboutPage?.sectionTwo?.activity.imageMap} alt="Map" />
            </div>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default AboutUsPage;
