import React from "react";
import "./anonymous.scss";
import MainHeader from "../header/MainHeader";
import Footer from "../footer/Footer";

const AnonymousLayout = ({ children, isHomePage }: any) => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [children]);

  return <div>
    <MainHeader/>

    {children}
    {!isHomePage && <Footer/>}
    </div>;
};

export default AnonymousLayout;
