import { Checkbox, Collapse } from 'antd';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import './collapse-filter.scss';
import { useState } from 'react';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';

type IProps = {
    listSelected: CheckboxValueType[]
    onChangeListSelected: (checkedValues: CheckboxValueType[]) => void
    header: string;
    options: { label: string, value: string }[]
}
const CollapseAllFilter = ({
    onChangeListSelected,
    listSelected,
    header,
    options
}
    : IProps
) => {
    const CheckboxGroup = Checkbox.Group;
    const { Panel } = Collapse;
    const [indeterminate, setIndeterminate] = useState(true);
    const [checkAll, setCheckAll] = useState(false);

    const onChange = (list: CheckboxValueType[]) => {
        onChangeListSelected(list);
        setIndeterminate(!!list.length && list.length < options.length);
        setCheckAll(list.length === options.length);
    };

    const onCheckAllChange = (e: CheckboxChangeEvent) => {
        // create array contains value from options
        const optionsValue = options.map((option) => option.value);
        onChangeListSelected(e.target.checked ? optionsValue : []);
        setIndeterminate(false);
        setCheckAll(e.target.checked);
    };
    return (
        <Collapse ghost className='CollapseFilter'>

            <Panel header={header} key="1" className='CollapseFilter__panel'>
                <Checkbox className='Checkbox__all' indeterminate={indeterminate} onChange={onCheckAllChange} checked={checkAll}>
                    All
                </Checkbox>
                <CheckboxGroup
                    className='Checkbox__container' value={listSelected}
                    options={options} onChange={onChange} />
            </Panel>
        </Collapse>
    )
}

export default CollapseAllFilter