import { Col, Row } from "antd";

import {
  FacebookIcon,
  InstagramIcon,
  ZaloIcon,
} from "../../../components/icons";
import recognize from "../../../assets/images/home/recognize.png";
import "../../../pages/contact/contact.scss";
import "./section.scss";
import { useSelector } from "../../../redux/store";
const SectionSevenMobile = () => {
  const { homePage } = useSelector((state) => state.editWebsite);
  const contact = homePage?.contact;
  return (
    <section className="sectionSeven">
      <div className="sectionSeven__wrapper">
        <section className="contact">
          <Row style={{ height: "100%" }}>
            <Col xs={24}>
              <div className="contact__left__container">
                <h2 className="contact__title">LIÊN HỆ</h2>
                <div className="contact__subtitle">
                  <span>
                    Liên hệ với chúng tôi để thảo luận về sự án của bạn
                  </span>
                </div>
                <Row>
                  <Col xs={24} md={12}>
                    <div className="contact__info__container">
                      <span className="contact__info__label">MAIL:</span>
                      <p className="contact__info__content">{contact?.mail}</p>
                    </div>
                  </Col>
                  <Col xs={24} md={12}>
                    <div className="contact__info__container">
                      <span className="contact__info__label">PHONE:</span>
                      <p
                        style={{ fontWeight: "bold" }}
                        className="contact__info__content"
                      >
                        {contact?.phone}
                      </p>
                    </div>
                  </Col>
                </Row>
                <div className="contact__info__container">
                  <span className="contact__info__label">Địa chỉ:</span>
                  <p className="contact__info__content">{contact?.address}</p>
                </div>
                <div className="contact__info__container">
                  <span className="contact__info__label">Địa chỉ xưởng:</span>
                  <p className="contact__info__content">
                    {contact?.factoryAddress}
                  </p>
                </div>
                <Row align={"middle"} className="contact__social__container">
                  <Col xs={6} md={4} xl={3}>
                    <span className="contact__social__label">Follow us:</span>
                  </Col>
                  <Col xs={18} md={12} xl={12}>
                    <div className="contact__social__icon__container">
                      <a href={`${contact?.linkFacebook}`} target="blank">
                        <div className="contact__social__icon">
                          <FacebookIcon />
                        </div>
                      </a>
                      <a href={`${contact?.linkInstagram}`} target="blank">
                        <div className="contact__social__icon">
                          <InstagramIcon />
                        </div>
                      </a>
                      <a href={`${contact?.linkZalo}`} target="blank">
                        <div className="contact__social__icon contact__social__icon--zalo">
                          <ZaloIcon />
                        </div>
                      </a>
                    </div>
                  </Col>
                  <Col xs={24} md={8} xl={7} className="contact__recognize">
                    <img src={recognize} />
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </section>
      </div>
    </section>
  );
};

export default SectionSevenMobile;
