import { Link } from 'react-router-dom';
import { NextButtonIcon, PlusButtonIcon } from '../../icons'
import { animateScroll as scroll } from 'react-scroll';
import './NextButton.scss'
type IProp = {
  isPlusIcon?: boolean,
  link?: string;
  isUp?: boolean;
  onClick?: () => void;
}
const NextButton = ({ isPlusIcon = false, link = "/", isUp = false, onClick }: IProp) => {
  const scrollToTop = () => {
    scroll.scrollToTop({
      duration: 1000,  // Scroll duration in milliseconds
      smooth: 'easeInOutQuart'  // Scroll easing function
    });
  };

  const clickHandle = () => {

    if (onClick) {
      onClick()


    } else {
     isUp && scrollToTop()

    }
  }

  return (
    <Link to={link} >
      <button onClick={clickHandle} className={`nextButton ${isUp && 'nextButton__up'}`}>
        {isPlusIcon ?
          <PlusButtonIcon />
          :
          <NextButtonIcon />
        }
      </button>
    </Link>
  )
}

export default NextButton