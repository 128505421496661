import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ParamsType } from "../../@types/paramsType";
import { ProvinceState } from "../../@types/province";
import provinceApi from "../../services/province.api";

export const getProvinceList = createAsyncThunk(
  "province/getList",
  async (params: ParamsType, { dispatch }) => {
    const { data } = await provinceApi.get(params);
    return data;
  }
);

const initialState: ProvinceState = {
  provinceList: [],
};

const slice = createSlice({
  name: "province",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getProvinceList.fulfilled, (state, action) => {
      state.provinceList = action.payload.items;
    });
  },
});

export default slice.reducer;
