import { RouteChildrenProps } from "react-router";
import { LayoutProps } from "../../components/views/layout/route-layout";
import TitleComponent from "../../components/views/title-component/TitleComponent";
import { Tabs } from "antd";
import InforServices from "../../components/views/services/infor/Infor";
import Procedure from "../../components/views/services/service/procedure/Procedure";
import TabsComponent from "../../components/tabs/Tabs";
import ServiceEvent from "../../components/views/event/service/ServiceEvent";
import { OBJECT_TYPE, WebcontentType } from "../../constants/app-constants";
import { dispatch, useSelector } from "../../redux/store";
import { getContentWebsite } from "../../redux/slices/edit-website";
import { useEffect } from "react";
interface Props extends RouteChildrenProps, LayoutProps {}
const tabItems = [
    {
      label: "Giới thiệu",
      key: "1",
      children: <InforServices type={OBJECT_TYPE.events.introduce}/>,
    },
    {
      label: "Dịch vụ",
      key: "2",
      children: <ServiceEvent/>,
    },
    {
      label: "Quy trình",
      key: "3",
      children: <Procedure type={OBJECT_TYPE.events.procedure}/>,
    },
  ];
export default function EventPage(props: Props) {
  const { eventOrganizePage } = useSelector((state) => state.editWebsite);

  useEffect(() => {
    if (!eventOrganizePage) {
      dispatch(getContentWebsite(WebcontentType.EventOrganize));
    } 
  }, [eventOrganizePage]);
  return (
    <div className="servicesPage">
      <TitleComponent
        title="TỔ CHỨC SỰ KIỆN"
        desc={eventOrganizePage?.content}
      />
      <TabsComponent items={tabItems}/>
    </div>
  );
}
