import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import i18next from 'i18next';
import ProductApi from '../../services/product.api';
import { IProductCreated, IProductParams, ProductState } from '../../@types/product';
import { ParamsType } from '../../@types/paramsType';
import { OBJECT_TYPE } from '../../constants/app-constants';
import { IOtherService } from '../../@types/otherService';

// ----------------------------------------------------------------------
type IDataCreated = {
  data: IProductCreated;
  id?: number | string;
  navigate: () => void;
}
type IProductCreatedDelete = {
  ids: number[] | string[];
  params: IProductParams;
}

export const getListGift = createAsyncThunk(
  'otherService/getListGift',
  async (params: ParamsType, { dispatch }) => {
    const { data } = await ProductApi.get({ ...params, categoryId: OBJECT_TYPE.otherService.gift });
    return data;
  }
);

export const getListFurnitureProduct = createAsyncThunk(
  'otherService/getListFurnitureProduct',
  async (params: ParamsType, { dispatch }) => {
    const { data } = await ProductApi.get({ ...params, categoryId: OBJECT_TYPE.otherService.furnitureProduct });
    return data;
  }
);



const initialState: IOtherService = {
  listGift: [],
  listFurnitureProduct: [],
};

const slice = createSlice({
  name: 'OtherService',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder.addCase(getListGift.fulfilled, (state, action) => {
      state.listGift = action.payload.items;
    });
    builder.addCase(getListFurnitureProduct.fulfilled, (state, action) => {
      state.listFurnitureProduct = action.payload.items;
    });
  },
});
export default slice.reducer;
export const {  } = slice.actions;
