import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';



import ContactApi from '../../services/contact.api';
import { IContact } from '../../@types/contact';


// ----------------------------------------------------------------------

export const createContact = createAsyncThunk(
  'contact/createContact',
  async (params: IContact, { dispatch }) => {
    const { data } = await ContactApi.post(params);
    return data;
  }
);

const initialState = {
};

const slice = createSlice({
  name: 'Contact',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
  },
});
export default slice.reducer;
