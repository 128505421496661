import { Col, Row, Select } from "antd";
import "./ServiceEvent.scss";
import useResponsive from "../../../../hooks/useResponsive";
import ContentComponent from "../../services/service/Content/Content";
import event1 from "../../../../assets/images/event/event1.png";
import event2 from "../../../../assets/images/event/event2.png";
import event3 from "../../../../assets/images/event/event3.png";
import event4 from "../../../../assets/images/event/event4.png";
import event5 from "../../../../assets/images/event/event5.png";
import { dispatch, useSelector } from "../../../../redux/store";
import { useEffect, useState } from "react";
import { getServiceEvent } from "../../../../redux/slices/service";
import { OBJECT_TYPE } from "../../../../constants/app-constants";

type Props = {};
const ServiceEvent = (props: Props) => {
  const [selectedPageSize, setSelectedPageSize] = useState("1");
  const { serviceList } = useSelector((state) => state.service);
  console.log(serviceList)
  const totalServices = serviceList.length;
  useEffect(() => {
    dispatch(
      getServiceEvent({
        pageIndex: 1,
        pageSize: 100,
        type: OBJECT_TYPE.service.event,
      })
    );
  }, []);
  function repeatImages(images: string[], targetLength: number): string[] {
    const repeatedImages = [];
    while (repeatedImages.length < targetLength) {
      repeatedImages.push(...images);
    }
    return repeatedImages.slice(0, targetLength);
  }
  const options = [
    { value: "100", label: "Tất cả sự kiện", },
    { value: "10", label: "10 sự kiện", disabled: totalServices < 10 },
    { value: "20", label: "20 sự kiện", disabled: totalServices < 20 },
    { value: "30", label: "30 sự kiện", disabled: totalServices < 30 },
  ];
  const handleSelectChange = (value: string) => {
    setSelectedPageSize(value);
    const pageSizeAsNumber = parseInt(value, 10);
    dispatch(
      getServiceEvent({
        pageIndex: 1,
        pageSize: pageSizeAsNumber,
        type: OBJECT_TYPE.service.event,
      })
    );
  };
  const { isMobile } = useResponsive();
  return (
    <div className="serviceTabs ">
      <Row>
        <Col xs={6} xl={12}></Col>
        <Col xs={18} xl={12} className="serviceTabs__header">
          <p className="subTitle serviceTabs__header--text">Hiển thị</p>
          <Select
            defaultValue={options[0].value}
            style={{ width: 260, background: "none", borderRadius: 40 }}
            className="serviceTabs__select"
            options={options}
            onChange={handleSelectChange}
          />
          {isMobile ? null : (
            <p className="subTitle">{totalServices} công trình</p>
          )}
        </Col>
      </Row>
      <div className="serviceTabs__content sectionContainer">
        <Row gutter={[30, 30]} className="serviceTabs__content__row">
          {serviceList.map((service, index) => {
            const serviceImages = service.images
              ? service.images.split(",")
              : [];
            return (
              <Col
                xs={24}
                sm={12}
                xl={index % 3 === 2 ? 24 : 12}
                style={{ paddingRight: "15px" }}
                className="serviceTabs__content__col"
                key={index}
              >
                <img src={service.thumbnail} alt="" />
                <ContentComponent
                  images={serviceImages}
                  desc={service.name ? service.name : ""}
                  title="TỔ CHỨC SỰ KIỆN"
                  time={service.description ? service.description : ""}
                  thumbnail={service.thumbnail}
                />
              </Col>
            );
          })}
        </Row>
      </div>
    </div>
  );
};
export default ServiceEvent;
