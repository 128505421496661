import { Carousel, Col, Row } from "antd";
import { useEffect, useState } from "react";
import { useLocation } from "react-router";
import sectionSix from "../../../assets/images/home/banner-section-six.png";
import { ROUTE_PATHS } from "../../../constants/url-config";
import useResponsive from "../../../hooks/useResponsive";
import { dispatch, useSelector } from "../../../redux/store";
import NextButton from "../../button/next-button/NextButton";
import {
  KyanIcon,
  SchneiderIcon,
  SpotifyIcon,
  UberIcon,
  VisaIcon,
} from "../../icons";
import { getContentWebsite } from "../../../redux/slices/edit-website";
import { WebcontentType } from "../../../constants/app-constants";
const LIST_PARTNER = [UberIcon, VisaIcon, SchneiderIcon, SpotifyIcon, KyanIcon];

const SectionSix = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const { isMobile } = useResponsive();
  const { pathname } = useLocation();
  const isPartner = pathname === ROUTE_PATHS.Partner;
  const { homePage } = useSelector((state) => state.editWebsite);
  const sectionSixContent = homePage?.sectionSix;
  const lengthCasousel =
    homePage && homePage.partner.length < 5 ? homePage.partner.length : 5;
  useEffect(() => {
    if (!homePage) {
      dispatch(getContentWebsite(WebcontentType.Home));
    }
  }, [homePage]);
  return (
    <>
      <div
        className={`sectionSix__partner ${isPartner ? "partner" : ""}`}
        id="partner"
      >
        <div className="sectionSix__partner__container">
          {/* divider */}
          <Row className="sectionSix__partner__divider__container">
            <Col xs={24} md={8} sm={8} lg={6} xl={4}>
              <span>KHÁCH HÀNG THÂN THIẾT</span>
            </Col>
            <Col
              xs={0}
              flex="auto"
              className="sectionSix__partner__divider__wrapper"
            >
              <div className="sectionSix__partner__divider"></div>
            </Col>
          </Row>
          <Row justify="start" className="sectionSix__partner__icon__container">
            <Carousel
              autoplaySpeed={1500}
              dots={false}
              className={`carouselCustom `}
              slidesToShow={isMobile ? 3 : lengthCasousel}
              autoplay
            >
              {homePage?.partner.map((img, index) => (
                <Col
                  span={4}
                  key={index}
                  className="sectionSix__partner__icon"
                  onClick={() => {
                    setCurrentImageIndex(index);
                    // showModal();
                  }}
                >
                  <img src={img} alt="partner" />
                </Col>
              ))}
              {/* {LIST_PARTNER.map((PartnerIcon, index) =>
                <Col span={4} key={index} className='sectionSix__partner__icon'
                  onClick={() => {
                    setCurrentImageIndex(index);
                    // showModal();
                  }}
                >
                  <PartnerIcon />
                </Col>)

              } */}
            </Carousel>

            {/* :
            (LIST_PARTNER.map((PartnerIcon, index) =>
              <Col span={4} key={index} className='sectionSix__partner__icon'>
                <PartnerIcon />
              </Col>
            ))
            } */}
          </Row>
        </div>
      </div>
      <div className="sectionSix">
        <Row className="sectionSix__container">
          <Col
            xs={24}
            md={24}
            sm={24}
            lg={14}
            xl={16}
            className="sectionSix__right__wrapper"
          >
            <div className="sectionSix__right__container">
              <div
                className={`sectionSix__right__image ${
                  isPartner ? "partner" : ""
                }`}
                style={{
                  backgroundImage: `url(${sectionSixContent?.banner})`,
                }}
              ></div>
            </div>
          </Col>
          <Col
            xs={24}
            md={24}
            sm={24}
            lg={10}
            xl={8}
            className="sectionSix__left__wrapper"
          >
            <Row style={{ height: "100%" }}>
              <Col xs={16} md={16} sm={16} xl={22} style={{ height: "100%" }}>
                <div className="sectionSix__left__container">
                  <div className="sectionSix__left__title">
                    <span>dịch vụ khác</span>
                  </div>

                  <Row justify={{ xl: "center", lg: "start" }} align="middle">
                    <Col xs={24} md={24} sm={24} lg={18} xl={18}>
                      <Row style={{ flexDirection: "column" }}>
                        <span className="sectionSix__left__nameProduct">
                          {sectionSixContent?.content}
                        </span>
                        <span className="sectionSix__left__contact">
                          Liên hệ
                        </span>
                      </Row>
                    </Col>
                    <Col xs={0} md={0} sm={0} lg={0} xl={6}>
                      <div className="sectionTwo__item__nextButton">
                        <NextButton
                          isPlusIcon
                          link={ROUTE_PATHS.ServicesOther}
                        />
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col
                xs={8}
                md={6}
                sm={6}
                xl={0}
                className="sectionSix__left__button"
              >
                <div className="sectionTwo__item__nextButton">
                  <NextButton isPlusIcon link={ROUTE_PATHS.ServicesOther} />
                </div>
              </Col>
              <Col
                xs={0}
                md={0}
                sm={0}
                lg={1}
                xl={2}
                className="sectionSix__dn"
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <div className="sectionTwo__item__divider"></div>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default SectionSix;
