import SectionSix from "./components/views/section/SectionSix";
import { ROUTE_PATHS } from "./constants/url-config";
import { PERMISSION } from "./guards/role-guard";
import AboutUsPage from "./pages/about-us/about";
import Contact from "./pages/contact/Contact";
import EventPage from "./pages/event/Event";
import HomePage from './pages/home/home';
import HRMPage from "./pages/hrm/HRM";
import RecruitmentDetail from "./pages/recruitment-detail/RecruitmentDetail";
import ServicesOtherPage from "./pages/services-other/ServicesOther";
import ServicesPage from "./pages/services/Services";

import SignIn from './pages/sign-in/sign-in';

export interface Route {
    groupIndex?: number
    href: string
    exact: boolean
    component: any
    title: string
    hidden?: boolean
    icon?: any
    forAdmin?: boolean
    loginRequired?: boolean
    permissions: PERMISSION[]
    subMenu?: SubMenu[]
}

interface SubMenu {
    href: string
    title: string
}

const anonymousPage: Route[] = [
    {
        href: ROUTE_PATHS.SignIn,
        title: "",
        exact: true,
        component: SignIn,
        hidden: true,
        permissions: [],
    },
]

const authorizedPage: Route[] = [
    {
        href: ROUTE_PATHS.Home,
        exact: true,
        component: HomePage,
        title: "Home",
        permissions: [],
        loginRequired: false,
    },
    {
        href: ROUTE_PATHS.Services,
        exact: true,
        component: ServicesPage,
        title: "Services",
        permissions: [],
        loginRequired: false,
    },
    {
        href: ROUTE_PATHS.HRM,
        exact: true,
        component: HRMPage,
        title: "HRM",
        permissions: [],
        loginRequired: false,
    },
    {
        href: ROUTE_PATHS.ServicesOther,
        exact: true,
        component: ServicesOtherPage,
        title: "ServicesOther",
        permissions: [],
        loginRequired: false,
    },
    {
        href: ROUTE_PATHS.AboutUs,
        exact: true,
        component: AboutUsPage,
        title: "About Us",
        permissions: [],
        loginRequired: false,
    },
    {
        href: ROUTE_PATHS.Event,
        exact: true,
        component: EventPage,
        title: "About Us",
        permissions: [],
        loginRequired: false,
    },
    {
        href: ROUTE_PATHS.Contact,
        exact: true,
        component: Contact,
        title: "Contact",
        permissions: [],
        loginRequired: false,
    },
    {
        href: ROUTE_PATHS.Partner,
        exact: true,
        component: SectionSix,
        title: "Partner",
        permissions: [],
        loginRequired: false,
    },
    {
        href: ROUTE_PATHS.RecruitmentDetail,
        exact: true,
        component: RecruitmentDetail,
        title: "Recruitment Detail",
        permissions: [],
        loginRequired: false,
    },
]

export const routes: Route[] = [...anonymousPage, ...authorizedPage]