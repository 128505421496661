import React, { useState } from "react";
import "./menu-header.scss";
import image from "../../../../../assets/images/Menu/1.png";
import image2 from "../../../../../assets/images/Menu/2.png";
import image3 from "../../../../../assets/images/Menu/3.png";
import image4 from "../../../../../assets/images/Menu/4.png";

import { Col, Row } from "antd";
import { useLocation } from "react-router";
import { ROUTE_PATHS } from "../../../../../constants/url-config";
import { Link } from "react-router-dom";

import useResponsive from "../../../../../hooks/useResponsive";
import SVGIcons from "../../../../icons/svgs";
import { useSelector } from "../../../../../redux/store";
import { RightOutlined } from '@ant-design/icons';
import MenuLanguage from "../../../../menu-language/MenuLanguage";

type Props = {
  active: boolean;
};

const MenuHeader = ({ active }: Props) => {
  const location = useLocation();
  const { pathname } = location;
  const { isMobile, isTablet } = useResponsive();
  const menuMobile = isMobile || isTablet;
  const { listMenuImage } = useSelector((state) => state.projectImage);
  const { images } = listMenuImage;
  const [activeDropDown, setActiveDropDown] = useState(false);
  const handleActiveDropdown = () => {
    setActiveDropDown(!activeDropDown);
  };
  return (
    <div className={`menu-header ${active ? "active" : ""}`}>
      <Row className="menu-header__container">
        <Col xs={24} md={24} lg={6} xl={6}>
          <div className="menu-header__content">
            <h2
              className={`menu-header__content__about ${
                pathname === ROUTE_PATHS.AboutUs && "active"
              }`}
            >
              <Link to={ROUTE_PATHS.AboutUs}>Về chúng tôi</Link>
            </h2>
            <h2
              className={`menu-header__content__service ${
                pathname === ROUTE_PATHS.Services && "active"
              } ${activeDropDown && "active-dropdown"}`}
              onClick={() => menuMobile && handleActiveDropdown()}
            >
              {menuMobile ? (
                <>Dịch vụ {activeDropDown ?
             
                <SVGIcons.DropDownIcon />: <RightOutlined style={{fontSize:15}} />} </>
              ) : (
                <Link to={ROUTE_PATHS.Services}>
                  Dịch vụ
                </Link>
              )}
            </h2>
            {menuMobile && (
              <div
                className={`content-service-wrapper ${
                  activeDropDown && "active-dropdown"
                }`}
              >
                <Link
                  to={ROUTE_PATHS.Services}
                  className={`img-item ${
                    ROUTE_PATHS.Services === pathname && "active"
                  }`}
                >
                  <div className="content-service-item">
                    Thiết kế & thi công
                  </div>
                </Link>

                <Link
                  to={ROUTE_PATHS.Event}
                  className={`img-item ${
                    ROUTE_PATHS.Event === pathname && "active"
                  }`}
                >
                  <div className="content-service-item">tổ chức sự kiện</div>
                </Link>

                <Link
                  to={ROUTE_PATHS.HRM}
                  className={`img-item ${
                    ROUTE_PATHS.HRM === pathname && "active"
                  }`}
                >
                  <div className="content-service-item">quản lý nhân sự</div>
                </Link>

                <Link
                  to={ROUTE_PATHS.ServicesOther}
                  className={`img-item ${
                    ROUTE_PATHS.ServicesOther === pathname && "active"
                  }`}
                >
                  <div className="content-service-item">dịch vụ khác</div>
                </Link>
              </div>
            )}

            <h2
              className={`menu-header__content__partner ${
                pathname === ROUTE_PATHS.Partner && "active"
              }`}
            >
              <Link to={ROUTE_PATHS.Partner}>ĐỐI tác</Link>
            </h2>
            {/* <LinkScroll
                  activeClass="active"
                  to="partner"
                  spy={true}
                  smooth={true}
                  offset={-70} // Adjust the offset as needed
                  duration={500} //   Duration of the scroll animation in milliseconds
                >
                  Scroll to Target Element
                </LinkScroll> */}
            <h2
              className={`menu-header__content__contact ${
                pathname === ROUTE_PATHS.Contact && "active"
              }`}
            >
              <Link to={ROUTE_PATHS.Contact}>Liên hệ</Link>
            </h2>
            <div className="menu-header__content__language">
              {menuMobile && (
                <MenuLanguage/>
              )}
            </div>
          </div>
        </Col>
        <Col xs={0} md={0} lg={18} xl={18}>
          <Row className="image-wrapper">
            <Col xs={6}>
              <Link
                to={ROUTE_PATHS.Services}
                className={`img-item ${
                  ROUTE_PATHS.Services === pathname && "active"
                }`}
              >
                <img src={images[0] ? images[0] : image} alt="image" />
                <div className="img-item__modal"></div>
                <h3 className="img-item__title">
                  Thiết kế <br />& thi công
                </h3>
              </Link>
            </Col>
            <Col xs={6}>
              <Link
                to={ROUTE_PATHS.Event}
                className={`img-item ${
                  ROUTE_PATHS.Event === pathname && "active"
                }`}
              >
                <img src={images[1] ? images[1] : image2} alt="image" />
                <div className="img-item__modal"></div>
                <h3 className="img-item__title">
                  Tổ chức <br /> Sự kiện
                </h3>
              </Link>
            </Col>
            <Col xs={6}>
              <Link
                to={ROUTE_PATHS.HRM}
                className={`img-item ${
                  ROUTE_PATHS.HRM === pathname && "active"
                }`}
              >
                <img src={images[2] ? images[2] : image3} alt="image" />
                <div className="img-item__modal"></div>
                <h3 className="img-item__title">
                  Quản lý <br />
                  Nhân sự
                </h3>
              </Link>
            </Col>
            <Col xs={6}>
              <Link
                to={ROUTE_PATHS.ServicesOther}
                className={`img-item ${
                  ROUTE_PATHS.ServicesOther === pathname && "active"
                }`}
              >
                <img src={images[3] ? images[3] : image4} alt="image" />
                <div className="img-item__modal"></div>
                <h3 className="img-item__title">
                  Dịch vụ <br />
                  khác
                </h3>
              </Link>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default MenuHeader;
