import { Col, Menu, Row } from "antd";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import logo from "../../../../assets/logo/logo.svg";
import { allLangs } from "../../../../constants/app-constants";
import { CookieConstants } from "../../../../constants/local";
import { ROUTE_PATHS } from "../../../../constants/url-config";
import useResponsive from "../../../../hooks/useResponsive";
import { setIsFirst } from "../../../../redux/slices/header";
import { dispatch, useSelector } from "../../../../redux/store";
import SVGIcons from "../../../icons/svgs";
import MenuLanguage from "../../../menu-language/MenuLanguage";
import "./header.scss";
import MenuHeader from "./menu-header/MenuHeader";

type Props = {};

const MainHeader = (props: Props) => {
  const { t } = useTranslation();

  const { isMobile, isTablet } = useResponsive();
  const menuMobile = isMobile || isTablet;
  const { isFirst } = useSelector((state) => state.header);
  const [activeMenu, setActiveMenu] = useState<boolean>(false);
  const [activeSearch, setActiveSearch] = useState<boolean>(false);



  const searchRef = useRef<HTMLDivElement>(null);
  const url = useLocation();
  
  useEffect(() => {
    const handleClickOutside = (e: any) => {
      if (searchRef.current && !searchRef.current.contains(e.target)) {
        setActiveSearch(false);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);
  useEffect(() => {
    if (url.pathname !== ROUTE_PATHS.Home) dispatch(setIsFirst(false));
  }, [])

  useEffect(() => {
    const shrinkHeader = () => {
      if (url.pathname !== ROUTE_PATHS.Home) return;
      if (document.documentElement.scrollTop < 100) {
        dispatch(setIsFirst(true));
      } else {
        dispatch(setIsFirst(false));
      }
    };

    window.addEventListener("scroll", shrinkHeader);
    return () => {
      window.removeEventListener("scroll", shrinkHeader);
    };
  }, []);

  return (
    <Row
      className={`header  ${activeMenu ? "active" : ""} ${isFirst && !activeMenu && "header--first-home"
        }`}
      justify="space-between"
      align="middle"
    >
      <Col
        xs={8}
        className={`header__logo ${isFirst && !activeMenu && "header__logo--first"
          }`}
      >
        <Link to={"/"}>
          <img src={logo} alt="Logo" />
        </Link>
      </Col>
      <Col
        xs={3}
        xl={8}
        className="header__tool"
        onClick={() => setActiveMenu(!activeMenu)}
      >
        {activeMenu ? <SVGIcons.MenuClose /> : <SVGIcons.MenuOpen />}
      </Col>
      <Col xs={10} xl={8} className="header__search" ref={searchRef}>
        <div className={`search-wrapper ${activeSearch ? "active" : ""}`}>
          <input
            name="search"
            className="search-wrapper__button"
            placeholder="Tìm kiếm"
          />
          <div className="search-wrapper__line"></div>
        </div>

        {activeMenu ? (
          menuMobile ? (
            <div
              className={`search-icon`}
              onClick={() => {
                setActiveSearch(!activeSearch);
              }}
            >
              <SVGIcons.SearchIcon />
            </div>
          )
            : (
              <MenuLanguage />
            ))
          : (
            !menuMobile && (
              <div
                className={`search-icon`}
                onClick={() => {
                  setActiveSearch(!activeSearch);
                }}
              >
                <SVGIcons.SearchIcon />
              </div>

            )
           )}
      </Col>
      <MenuHeader active={activeMenu} />
    </Row>
  );
};

export default MainHeader;
