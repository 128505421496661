import { SVGProps } from "react"

const StarIcon = (props: SVGProps<SVGSVGElement>) => {
    return (
        <svg {...props} width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.55008 6.84276C9.3947 6.98807 9.32331 7.19821 9.3587 7.4043L9.89203 10.2525C9.93703 10.4939 9.83144 10.7382 9.62207 10.8778C9.4169 11.0225 9.14393 11.0399 8.92016 10.9241L6.26311 9.58679C6.17072 9.53932 6.06813 9.51385 5.96315 9.51095H5.80057C5.74417 9.51906 5.68898 9.53642 5.63859 9.56305L2.98094 10.9067C2.84955 10.9704 2.70077 10.993 2.55499 10.9704C2.19984 10.9055 1.96287 10.579 2.02106 10.2346L2.55499 7.38636C2.59039 7.17853 2.519 6.96723 2.36362 6.81961L0.1973 4.79343C0.0161234 4.62381 -0.0468684 4.36909 0.0359208 4.13926C0.11631 3.91001 0.321483 3.74271 0.569251 3.70508L3.55086 3.28768C3.77763 3.26511 3.97681 3.13196 4.07879 2.93513L5.39262 0.335828C5.42382 0.277937 5.46401 0.224678 5.51261 0.179523L5.5666 0.138999C5.59479 0.108896 5.62719 0.0840028 5.66319 0.063741L5.72858 0.0405846L5.83056 6.10352e-05H6.08313C6.3087 0.0226385 6.50728 0.152893 6.61106 0.347406L7.94229 2.93513C8.03828 3.12443 8.22485 3.25584 8.44022 3.28768L11.4218 3.70508C11.6738 3.73981 11.8844 3.9077 11.9678 4.13926C12.0464 4.3714 11.9786 4.62612 11.7938 4.79343L9.55008 6.84276Z" fill="#E2E7F1" />
        </svg>

    )
}

export default StarIcon