import { Col, Row } from "antd";
import procedure1 from "../../../../../assets/images/services/procedure1.png";
import procedureMobile1 from "../../../../../assets/images/services/procedure_mobile1.png";
import procedure2 from "../../../../../assets/images/services/procedure2.png";
import procedure3 from "../../../../../assets/images/services/procedure3.png";
import procedure4 from "../../../../../assets/images/services/procedure4.png";
import procedure5 from "../../../../../assets/images/services/procedure5.png";
import "./Procedure.scss";
import useResponsive from "../../../../../hooks/useResponsive";
import { dispatch, useSelector } from "../../../../../redux/store";
import { useLocation } from "react-router";
import { useEffect } from "react";
import { getServiceProcedure } from "../../../../../redux/slices/serviceEvent";
type Props = {
  type: string;
};
const Procedure = ({ type }: Props) => {
  const { isMobile } = useResponsive();
  const { isTablet } = useResponsive();
  const { serviceProcedure} = useSelector((state) => state.serviceEvent)
  const location = useLocation();
  const { objectName } = serviceProcedure;
  useEffect(() => {
      dispatch(getServiceProcedure(type))
  }, [location,type])
  return (
    <>
    { objectName ?
 <div dangerouslySetInnerHTML={{ __html: objectName }}></div>
    :
      <div className="procedure containerCustom">
        {isMobile && isTablet ? (
          <img src={procedureMobile1} alt="" className="procedure__imgCustom" />
        ) : (
          <img src={procedure1} alt="" className="procedure__imgCustom" />
        )}
        <div className="procedure__title">
          <p className="procedure__title__content procedure__title__content--top">
            Quy Trình
          </p>
          <p className="procedure__title__content procedure__title__content--bottom">
            Công Việc
          </p>
        </div>
        <Row gutter={[24, 40]} className="sectionContainer">
          <Col xs={24} xl={12}>
            <p className="subTitle">
              Sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna
              aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud
              exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea
              commodo consequat. Lorem ipsum dolor sit amet, consectetuer
              adipiscing elit, sed diam nonummy nibh euismod tincidunt ut
              laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim
              veniam, quis nostrud exerci tation ullamcorper suscipit lobortis
              nisl ut aliquip ex ea commodo consequat.{" "}
            </p>
            <br />
            <p className="subTitle">
              Duis autem vel eum iriure dolor in hendrerit in vulputate velit
              esse molestie consequat, vel illum dolore eu feugiat nulla
              facilisis at vero eros et accumsan et iusto odio dignissim
            </p>
          </Col>
          <Col xs={24} xl={12} className="procedure__desc">
            <p className="subTitle">
              Sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna
              aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud
              exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea
              commodo consequat. Lorem ipsum dolor sit amet, consectetuer
              adipiscing elit, sed diam nonummy nibh euismod tincidunt ut
              laoreet dolore magna aliquam erat volutpat.
            </p>
          </Col>
        </Row>
        <div className="procedure__content ">
          <img src={procedure2} alt="" className="procedure__content__img" />
          <p className="subTitle sectionContainer">
            Sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna
            aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud
            exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea
            commodo consequat. Lorem ipsum dolor sit amet, consectetuer
            adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet
            dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam,
            quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut
            aliquip ex ea commodo consequat.
            <br />
            Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse
            molestie consequat, vel illum dolore eu feugiat nulla facilisis at
            vero eros et accumsan et iusto odio dignissim
          </p>
          <Row gutter={{ xs: 13, xl: 32 }} className="procedure__footer" >
            <Col xs={12} xl={12} className="procedure__footer--left">
              <Row className="procedure__footer--left--img">
                <img src={procedure3} alt="" />
              </Row>
              <Row>
                <Col span={12}></Col>
                <Col xs={12} xl={12}><img src={procedure4} alt="" /></Col>

              </Row>
            </Col>
            <Col xs={12} xl={12}>
              <img src={procedure5} alt="" />
            </Col>
          </Row>
        </div>
      </div>
    }
    </>
  );
};
export default Procedure;
