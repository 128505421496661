import { Button, Card, Col, Modal, Row } from "antd";
import Meta from "antd/lib/card/Meta";
import { useEffect, useState } from "react";
import other1 from "../../../../../assets/images/services/other/other1.png";
import other2 from "../../../../../assets/images/services/other/other2.png";
import other3 from "../../../../../assets/images/services/other/other3.png";
import other4 from "../../../../../assets/images/services/other/other4.png";
import other5 from "../../../../../assets/images/services/other/other5.png";
import other6 from "../../../../../assets/images/services/other/other6.png";
import { dispatch, useSelector } from "../../../../../redux/store";
import "./Furniture.scss";
import { getListFurnitureProduct, getListGift } from "../../../../../redux/slices/otherService";
import { DEFAULT_PAGINATION } from "../../../../../constants/app-constants";
import { IProduct } from "../../../../../@types/product";
import { Link } from "react-router-dom";
import { ROUTE_PATHS } from "../../../../../constants/url-config";

type Props = {
  isGift: boolean;
};

const Furniture = ({
  isGift,
}: Props) => {
  const [params, setParams] = useState({
    pageIndex: DEFAULT_PAGINATION.PAGE_INDEX,
    pageSize: DEFAULT_PAGINATION.PAGE_SIZE,
    keyword: '',
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalProduct, setModalProduct] = useState<IProduct>({});
  const { listGift, listFurnitureProduct } = useSelector((state) => state.otherService);
  let productData = isGift ? listGift : listFurnitureProduct;
  const showModal = (product: IProduct) => {
    setIsModalOpen(true);
    setModalProduct(product);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setModalProduct({});
  };

  // const productData = [
  //   {
  //     id: 1,
  //     imageSrc: other1,
  //     title: 'Tên Sản Phẩm 1',
  //     description: 'LIÊN HỆ',
  //   },
  //   {
  //     id: 2,
  //     imageSrc: other2,
  //     title: 'Tên Sản Phẩm 2',
  //     description: 'LIÊN HỆ',
  //   },
  //   {
  //     id: 3,
  //     imageSrc: other3,
  //     title: 'Tên Sản Phẩm 3',
  //     description: 'LIÊN HỆ',
  //   },
  //   {
  //     id: 4,
  //     imageSrc: other4,
  //     title: 'Tên Sản Phẩm 4',
  //     description: 'LIÊN HỆ',
  //   },
  //   {
  //     id: 5,
  //     imageSrc: other5,
  //     title: 'Tên Sản Phẩm 5',
  //     description: 'LIÊN HỆ',
  //   },
  //   {
  //     id: 6,
  //     imageSrc: other6,
  //     title: 'Tên Sản Phẩm 6',
  //     description: 'LIÊN HỆ',
  //   },
  // ];

  const getDataHandle = async () => {
    if (isGift && !listGift.length) {
      dispatch(getListGift(params))
      return
    }
    if (!listFurnitureProduct.length) {
      dispatch(getListFurnitureProduct(params))
    }
  }

  useEffect(() => {
    getDataHandle();
  }, [isGift])

  return (
    <div className="furniture">
      <Row gutter={[30, 60]} className="furniture__items">
        {productData.map((product) => (
          <Col xs={24} sm={12} xl={8} key={product.id}>
            <Card
              onClick={() => showModal(product)}
              className="furniture__card"
              hoverable
              bordered={false}
              style={{
                background: 'black',
              }}
              cover={<div className="furniture__card__img">
                <img className="furniture__card__img--layout" alt="example" src={product.thumbnail} />
              </div>}
            >
              <Meta
                className="furniture__card__content"
                title={product.name}
                description="LIÊN HỆ"
              />
            </Card>
          </Col>
        ))}
      </Row>

      <Modal
        style={{ width: "100%" }}
        className="furniture__modal"
        footer={false}
        open={isModalOpen}
        centered
        onCancel={handleCancel}
      >
        <Row gutter={{ xs: 0, sm: 30, xl: 60 }} className="furniture__modal--item">
          <Col xs={24} sm={12} xl={12}>
            <img src={modalProduct.thumbnail} alt="" />
          </Col>
          <Col xs={24} sm={12} xl={12}>
            <h2 className="modal--title">{modalProduct.name}</h2>
            <p className="modal--desc subTitle">KÍCH THƯỚC VÀ TRỌNG LƯỢNG</p>
            <Row gutter={[42, 10]} className="modal--text">
              <Col xs={24} sm={12} xl={12} className="modal--content">
                <ul>
                  {
                    modalProduct.specifications && modalProduct.specifications.split(',').splice(0, 3).map((item, index) => (
                      <li className="subTitle" key={index}>
                        {item}
                      </li>
                    ))
                  }
                  {/* <li className="subTitle">
                    Cao: <span>76 cm.</span>
                  </li>
                  <li className="subTitle">
                    Ngang: <span>184 cm.</span>
                  </li>
                  <li className="subTitle">
                    Sâu: <span>91 cm.</span>
                  </li>
                  <li className="subTitle">
                    Chiều cao chỗ ngồi: <span>42 cm.</span>
                  </li> */}
                </ul>
              </Col>
              <Col xs={24} sm={12} xl={12} className="modal--content">
                <ul>
                  {
                    modalProduct.specifications && modalProduct.specifications.length > 4 && modalProduct.specifications.split(',').slice(3).map((item, index) => (
                      <li className="subTitle" key={index}>
                        {item}
                      </li>
                    ))
                  }
                  {/* <li className="subTitle">
                    Chiều cao tay vịn: <span>76 cm.</span>
                  </li>
                  <li className="subTitle">
                    Chiều cao chân: <span>3 cm.</span>
                  </li>
                  <li className="subTitle">
                    Nặng: <span>56 kg</span>
                  </li>
                  <li className="subTitle">
                    Chỗ ngồi: <span>2 1/2</span>
                  </li> */}
                </ul>
              </Col>
            </Row>
            <p className="modal--desc subTitle">NGUYÊN VẬT LIỆU</p>
            <Row>
              <Col span={24} className="modal--content">
                <ul className="modal--content--footer">
                  {
                    modalProduct.material && modalProduct.material.split(',').map((item, index) => (
                      <li className="subTitle" key={index}>
                        {item}
                      </li>
                    ))
                  }
                  {/* <li className="subTitle">
                    Thành phần: <span>100% polyester.</span>
                  </li> */}
                </ul>
              </Col>
            </Row>
            <Link to={ROUTE_PATHS.Contact}>
            <Button className="modal--button subTitle">LIÊN HỆ</Button>
            </Link>
          </Col>
        </Row>
      </Modal>
    </div>
  );
};

export default Furniture;
