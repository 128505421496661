
const ZaloIcon = () => {
    return (

        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
            width="200.000000pt" height="72.000000pt" viewBox="0 0 200.000000 72.000000"
            preserveAspectRatio="xMidYMid meet">
            <metadata>
            </metadata>
            <g transform="translate(0.000000,72.000000) scale(0.100000,-0.100000)"
                fill="#000000" stroke="none">
                <path d="M10 660 l0 -60 191 -2 192 -3 -197 -243 c-188 -234 -196 -246 -196
-288 l0 -44 274 0 c311 0 306 -1 306 76 l0 44 -198 2 -199 3 184 230 c200 250
213 269 213 315 l0 30 -285 0 -285 0 0 -60z"/>
                <path d="M1242 380 c3 -382 -2 -360 81 -360 l47 0 0 350 0 350 -65 0 -66 0 3
-340z"/>
                <path d="M773 549 c-70 -27 -138 -102 -159 -177 -40 -135 27 -278 155 -335 61
-27 160 -26 222 1 45 19 46 19 57 1 9 -15 24 -19 62 -19 l50 0 0 270 0 270
-60 0 c-52 0 -60 -3 -60 -19 0 -18 -2 -18 -42 3 -61 29 -158 32 -225 5z m193
-131 c121 -82 62 -278 -83 -278 -165 0 -217 215 -70 290 51 26 103 22 153 -12z"/>
                <path d="M1605 546 c-212 -100 -211 -399 3 -506 67 -34 168 -33 237 3 103 53
154 136 155 253 0 115 -59 207 -164 255 -61 28 -167 25 -231 -5z m203 -123
c70 -52 91 -132 51 -202 -28 -50 -64 -73 -119 -79 -91 -8 -159 48 -168 138 -6
60 24 118 77 149 44 27 119 24 159 -6z"/>
            </g>
        </svg>

    )
}

export default ZaloIcon