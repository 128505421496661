import { Col, Row } from "antd";
import firstImage from "../../../assets/images/home/section-two-first.png";
import twoImage from "../../../assets/images/home/section-two-two.png";
import NextButton from "../../button/next-button/NextButton";
import { ROUTE_PATHS } from "../../../constants/url-config";
import { useSelector } from "../../../redux/store";
import { useEffect } from "react";
import { IRenderProjectImage } from "../../../@types/projectImage";
import useResponsive from "../../../hooks/useResponsive";

type IProps = {
  isActive: boolean;
};

const SectionTwo = ({ isActive }: IProps) => {
  const { listHomeSectionTwo } = useSelector((state) => state.projectImage);
  const { images } = listHomeSectionTwo;
  const { isLargeDesktop } = useResponsive();

  const { homePage } = useSelector((state) => state.editWebsite);
  const sectionTwoContent = homePage?.sectionTwo;

  return (
    <div className="sectionTwo home-section">
      <Row className="sectionTwo__list">
        <Col xs={24} sm={24} md={24} xl={8} className="sectionTwo__item">
          {/* image */}
          <div
            className="sectionTwo__item__image sectionTwo__item__image--first"
            style={{
              backgroundImage: `url(${sectionTwoContent?.bannerLeft})`,
            }}
          ></div>
          <div className="sectionTwo__item__divider__bottom"></div>
          <div
            className={`sectionTwo__item__title ${isActive ? "animate" : ""}`}
          >
            {isLargeDesktop ? (
              <>
                <span>Thiết kế</span>
                <span className="sectionTwo__item__title--bottom">
                  & thi công
                </span>
              </>
            ) : (
              <span>Thiết kế & thi công</span>
            )}
          </div>
        </Col>
        <div className="sectionTwo__item__divider"></div>
        <Col
          xs={24}
          sm={24}
          md={24}
          xl={8}
          className="sectionTwo__item sectionTwo__item--two"
        >
          <div
            className="sectionTwo__item__image sectionTwo__item__image--two"
            style={{
              backgroundImage: `url(${sectionTwoContent?.bannerRight})`,
            }}
          ></div>
        </Col>
        <div className="sectionTwo__item__divider"></div>
        <Col
          xs={24}
          sm={24}
          md={24}
          xl={8}
          className="sectionTwo__item sectionTwo__item--three"
        >
          <span>{sectionTwoContent?.content}</span>
          <div className="sectionTwo__item__nextButton">
            <NextButton isPlusIcon link={ROUTE_PATHS.Services} />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default SectionTwo;
